import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCompanyData,
  deleteCompany,
  listCompany,
} from "../../../redux/actions/company";
import { Button, Pagination, Table } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setCompanyHeader } from "../../../utils/setAuthToken";
import { Grid, Typography } from "@material-ui/core";
import axios from "axios";
import { BRM } from "../../Projects/AllProjects/CONSTANTS";
import { DIALOGS } from "../../../commons/CONSTANTS";
import useStyles from "../../../styles/jss/style";

export default function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const auth = useSelector((state) => state.auth);
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const [companyId, setCompanyId] = useState("");
  const history = useHistory();
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => {},
    },
    btn2: {
      text: "Yes",
      onClick: () => {},
    },
  });

  useEffect(() => {
    if (localStorage.getItem("companyId"))
      setCompanyId(localStorage.getItem("companyId"));
    // localStorage.removeItem('companyId');
    setCompanyHeader("");
    // dispatch(listCompany({ skip: 0 }));
  }, []);
  useEffect(() => {
    let query = { skip: 10 * (page - 1) };
    if (auth.data?.role?.name === BRM) {
      query.userId = auth.data?._id;
    }
    dispatch(listCompany(query));
  }, [page, auth]);

  useEffect(() => {
    // console.log(company, Math.ceil(company.list.data.totalCount / 1))
    if (company.list.data.data) {
      let list = company.list.data.data.map((itm) => {
        itm.companyLogin =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port != 3000 ? "" : ":" + window.location.port) +
          "/company-login/" +
          itm._id;
        itm.users = itm._id;
        itm.userRoles = itm._id;
        if (itm.logo) itm.logo = axios.defaults.baseURL + "/file/" + itm.logo;
        itm.name = (
          <Grid item>
            {itm.logo ? (
              <img
                // width="80"
                height="80"
                alt="image"
                style={{ minWidth: 80 }}
                src={itm.logo}
              />
            ) : null}
            <Typography>
              {itm.name} ({itm.companyId})
            </Typography>
          </Grid>
        );
        return itm;
      });
      setdata(list);
    }
    if (company.delete && company.delete.meta) {
      // console.log(company.delete);
      dispatch(clearCompanyData());
      let query = { skip: 10 * (page - 1) };
      if (auth.data?.role?.name === BRM) {
        query.userId = auth.data?._id;
      }
      dispatch(listCompany(query));
    }
  }, [company]);

  const onEdit = (e, row) => {
    history.push("/edit/company/" + row._id);
  };
  const onLogin = (row, column) => {
    // localStorage.setItem('companyId', encrypt(row._id, 'secret'));
    // console.log(
    //   row,
    //   column,
    //   (auth.data?.role?.name === BRM ? "/brm-" : "/") +
    //     "company-user-list/" +
    //     row._id
    // );
    if (column.label != "Roles")
      history.push(
        (auth.data?.role?.name === BRM ? "/brm-" : "/") +
          "company-user-list/" +
          row._id
      );
    else
      history.push(
        (auth.data?.role?.name === BRM ? "/brm-" : "/") +
          "company-role-list/" +
          row._id
      );
  };

  const onDelete = (i, e, item) => {
    // console.log(item, i)
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete company
      . Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => dispatch(deleteCompany(item._id)),
      },
    }));
  };
  // const onDelete = (i, e, row) => {
  //     dispatch(deleteCompany(row._id));
  // };

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>Company List</h2>
        {!companyId ? (
          <Button
            width="200px"
            text="Add Company"
            variant="contained"
            color="primary"
            onClick={() => history.push("/add/company")}
          />
        ) : null}
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          onLogin={onLogin}
          actions={companyId ? false : true}
        />
        <Grid item container style={{ justifyContent: "center" }}>
          <div className="ui-components">
            <Pagination
              count={company.list.data.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

import { Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, Typography, makeStyles, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Pagination, Table } from "../../../commons/mui";
import { deleteAccident, listAccidents, reportAccident } from "../../../redux/actions/accident";
import { TABLE_HEADER } from "./CONSTANTS";
import { DIALOGS } from "../../../commons/CONSTANTS";
import Bay from '../../Cards/Bay';
import ReactApexChart from "react-apexcharts";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 310,
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
  input: {
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
    },
  },
}));

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const [accidentList, setAccidentList] = useState([]);
  const [accidentReport, setAccidentReport] = useState({});
  const [reportOpen, setReportOpen] = useState(false);
  const [type, setType] = useState('');
  const accident = useSelector((state) => state.accident);
  const [page, setPage] = useState(1);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => { },
    },
    btn2: {
      text: "Yes",
      onClick: () => { },
    },
  });

  useEffect(() => {
    dispatch(reportAccident({}));
  }, []);
  useEffect(() => {
    dispatch(listAccidents({ skip: (page - 1) * 10 }));
  }, [page]);

  useEffect(() => {
    // console.log(accident)
    if (accident.list.data) {
      setAccidentList(accident.list.data);
    }
    if (accident.report?.data?.total) {
      setAccidentReport({ ...accidentReport, ...accident.report?.data });
    }
  }, [accident]);
  useEffect(() => {
    dispatch(listAccidents({ type, skip: (page - 1) * 10 }));
  }, [type]);
  const onDelete = (i, e, item) => {
    // console.log(item, i, item.name || item.projectNo)
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete accident. Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => {
          dispatch(deleteAccident(item._id)); setTimeout(() => {
            dispatch(listAccidents({ skip: (page - 1) * 10 }));
          }, 500);
        },

      },
    }));
  };
  const goToDetail = (item) => {
    if (item.total) {
      setType(item.type);
      setReportOpen(true)
    }
  }
  return (
    <Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>Incidents List</h2>
        <Button
          width="200px"
          text="Add Incident"
          variant="contained"
          color="primary"
          onClick={() => history.push("/add/accident")}
        />
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
      <Grid style={{ marginTop: "1rem" }}>
        {accidentReport.total ?
          <Grid item container sm={12} direction='row'>
            <Typography item variant='h6' key={'heading'} style={{ width: '100%' }} className="ui-component-headings">Incidents Report
              <span style={{ float: 'right' }}>Total Incidents: <Link style={{ cursor: 'pointer', color: '#fff' }} onClick={() => goToDetail({ type: '', total: accidentReport.total })}>{accidentReport.total}</Link></span>
            </Typography>
            <Grid item container spacing={2} sm={12} style={{ justifyContent: 'center' }}>
              <Grid item container spacing={1} xs={12} sm={6} style={{ justifyContent: 'center' }}>
                {/* <Grid item sm={4} >
                  <Grid item className="ui-component-block">
                    <Grid item className="ui-components">
                      <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                        <Bay key={'test-1-1-1-1'} title='Total Incidents' color={theme.palette.primary} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail({ type: '', total: accidentReport.total })}>{accidentReport.total}</Link>} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
                {accidentReport.report?.map((item, i) => (
                  <Grid item sm={6} style={i <= 1 ? { marginBottom: '9.8rem' } : {}} >
                    <Grid item className="ui-component-block">
                      <Grid item className="ui-components">
                        <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                          <Bay key={'test-2-1-1-1'} title={item.type} color={item.total == 0 ? theme.palette.green : item.total >0 && item.type === 'Near Misses' /*['Near Misses', 'Pro-Active Near Misses'].indexOf(item.type) != -1*/ ? theme.palette.amber : item.total > 0 && item.type === 'Accident' ? theme.palette.red : item.total > 0 && item.type==='Pro-Active Near Misses' ? theme.palette.yellow : theme.palette.primary} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail(item)}>{item.total}</Link>} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              {accidentReport.report?.length ?
                <Grid item container xs={12} sm={6} style={{ justifyContent: 'center', paddingTop: 20 }}>
                  <Grid item sm={12} >
                    <Card>
                      <CardContent style={{ minHeight: '20rem' }}>
                        <ReactApexChart options={{
                          chart: {
                            width: 250,
                            type: 'donut',
                          },
                          labels: accidentReport.report?.map(a => a.type),
                          colors: [theme.palette.red.main, theme.palette.amber.main, theme.palette.yellow.main,theme.palette.primary.main],
                          fill: {
                            type: 'gradient',
                          },
                          title: {
                            text: 'Performance',
                            align: 'center'
                          },
                          plotOptions: {
                            pie: {
                              startAngle: -90,
                              endAngle: 270
                            }
                          },
                          legend: {
                            position: 'bottom',
                            show: true
                          }
                        }} series={accidentReport.report?.map(a => a.total)} type="donut" />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                : null}

            </Grid>
          </Grid>
          : null}

      </Grid>


      <Dialog
        open={reportOpen}
        onClose={() => setReportOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{type + " Incidents List"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              item
              container
              style={{ justifyContent: "center" }}
            >
              <Table
                header={TABLE_HEADER}
                data={accidentList}
                onDelete={onDelete}
                // onDelete={(i, e, row) => {
                //   dispatch(deleteAccident(row._id));
                //   setTimeout(() => {
                //     dispatch(listAccidents({ skip: (page - 1) * 10 }));
                //   }, 500);
                // }}
                onEdit={(i, row) => {
                  history.push(`/edit/accident/${row._id}`)
                }}
              />
              <div className="ui-components">
                <Pagination
                  count={accident.list.totalCount}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setReportOpen(false)
            }}
            text="Ok"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

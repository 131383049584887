import {
  LIST_WORK_PLAN_VEHICLES,
  GET_WORK_PLAN_VEHICLES,
  ADD_WORK_PLAN_VEHICLES,
  UPDATE_WORK_PLAN_VEHICLES,
  DELETE_WORK_PLAN_VEHICLES,
} from "../action_types";
import axios from "axios";
// import { QS } from '../../utils/urlHelper';

export const listProjectPlanVehicle = (params) => async (dispatch) => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: "GET",
      url: `/project_workplan_vehicles`,
      params,
    });
    dispatch({
      type: LIST_WORK_PLAN_VEHICLES,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: LIST_WORK_PLAN_VEHICLES,
      payload: {
        data: [],
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      },
    });
  }
};

export const getProjectPlanVehicle = (id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "GET",
      url: `/project_workplan_vehicles/${id}`,
    });
    dispatch({
      type: GET_WORK_PLAN_VEHICLES,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_WORK_PLAN_VEHICLES,
      payload: {
        data: {},
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      },
    });
  }
};

export const addProjectPlanVehicle = (data) => async (dispatch) => {
  try {
    let res = await axios({
      method: "POST",
      url: `/project_workplan_vehicles`,
      data,
    });
    dispatch({
      type: ADD_WORK_PLAN_VEHICLES,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: ADD_WORK_PLAN_VEHICLES,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      },
    });
  }
};

export const updateProjectPlanVehicle = (data, id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "PUT",
      url: `/project_workplan_vehicles/${id}`,
      data,
    });
    dispatch({
      type: UPDATE_WORK_PLAN_VEHICLES,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: UPDATE_WORK_PLAN_VEHICLES,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      },
    });
  }
};

export const deleteProjectPlanVehicle = (id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "DELETE",
      url: `/project_workplan_vehicles/${id}`,
    });
    dispatch({
      type: DELETE_WORK_PLAN_VEHICLES,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: DELETE_WORK_PLAN_VEHICLES,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      },
    });
  }
};
export const clearPlanVehicleData = (type) => async (dispatch) => {
  dispatch({
    type: type || UPDATE_WORK_PLAN_VEHICLES,
    payload: {
      data: null,
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
}; 

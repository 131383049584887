import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography, makeStyles, styled, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { clearPlanData, getPlan, updatePlan } from "../../../redux/actions/projectPlan";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import LinearProgress from '@material-ui/core/LinearProgress';
import { clearProjectData, getProject, updateProject } from "../../../redux/actions/project";
import { ActionTable, AutoComplete, Backdrop, Button, IconButton, Table, TextField } from "../../../commons/mui";
import { clearTechnicianPlanData, listTechnicianPlans, updateTechnicianPlan } from "../../../redux/actions/technicianPlan";
import { ArrowBack, ArrowRightAlt, KeyboardBackspace, CloudUpload as CloudUploadIcon, Close } from "@material-ui/icons";
import { CHECKLIST_TABLE_HEADER, TABLE_HEADER, TABLE_HEADER_ACTIVITY, TABLE_HEADER_HISTORY } from "./CONSTANTS";
import { ADD_PLAN_ACTIVITY, ADD_PLAN_ACTIVITY_LOG, GET_PLAN_ACTIVITY, GET_PROJECT, GET_WORK_PLAN, LIST_PLAN_ACTIVITY, LIST_TECHNICIAN_WORK_PLAN, UPDATE_OBJECT_CHECKPOINT, UPDATE_PLAN_ACTIVITY, UPDATE_TECHNICIAN_WORK_PLAN, UPDATE_WORK_PLAN } from "../../../redux/action_types";
import moment from "moment";
import { ProjectDetailComponent } from "../../Projects";
import { uploadFiles } from "../../../redux/actions/file";
import { DIALOGS } from "../../../commons/CONSTANTS";
import { listTechnicianPlanLogs } from "../../../redux/actions/technicianPlanLog";
import ForwardIcon from '@material-ui/icons/Forward';
import { clearCheckpointData, updateCheckpoint } from "../../../redux/actions/objectCheckpoint";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { addPlanActivity, clearPlanActivityData, listPlanActivities, updatePlanActivity } from "../../../redux/actions/planActivity";
import { addPlanActivityLog, clearPlanActivityLogData } from "../../../redux/actions/planActivityLog";
import { updateProjectPlanVehicle } from "../../../redux/actions/projectPlanVehicle";


const useStyles = makeStyles((theme) => ({
  divider: {
    borderRight: "1px solid #838584",
    // height: '80vh',
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  align: {
    textAlign: "center",
  },
  space: {
    marginBottom: ".5rem",
  },
  selected: {
    cursor: "pointer",
    border: ".2rem solid green",
  },
  nokselected: {
    cursor: "pointer",
    border: ".2rem solid red",
  },
  notSelected: {
    cursor: "pointer",
  },
  btn: {
    // position: "absolute",
    width: "100%",
    textAlign: 'center',
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      bottom: "-5rem"
    },
    [theme.breakpoints.up("sm")]: {
      // position: "inline",
      bottom: "0rem"
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);



export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  const [list, setlist] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [technicianPlanData, setTechnicianPlanData] = useState({});
  const [planDetailList, setPlanDetailList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [checkedPart, setCheckedPart] = useState(0);
  const [totalPart, setTotalPart] = useState(0);
  const [allOk, setallOk] = useState(false);
  const [action, setAction] = useState(false);
  const { planId } = useParams();
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.projectPlan);
  const project = useSelector((state) => state.project);
  const technicianPlan = useSelector((state) => state.technicianPlan);
  const technicianPlanHistory = useSelector((state) => state.technicianPlanHistory);
  const planActivity = useSelector((state) => state.planActivity);
  const planActivityLog = useSelector((state) => state.planActivityLog);
  const uploads = useSelector((state) => state.file);
  const auth = useSelector((state) => state.auth);
  const projectPlanVehicle = useSelector(state => state.projectPlanVehicle);
  const localCompany = useSelector((state) => state.localCompany);
  const objectCheckpoint = useSelector((state) => state.objectCheckpoint);
  const [open, setOpen] = useState(false);
  const [isRectify, setIsRectify] = useState(false);
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [activeSide, setActiveSide] = useState("");
  const [vin, setVin] = useState({});
  const [sides, setSides] = useState([]);
  const [grids, setGrids] = useState([]);
  const [actualGrids, setActualGrids] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [allCheckList, setAllCheckList] = useState([]);
  const [allVehicleList, setAllVehicleList] = useState([]);
  const [selectedGridRow, setSelectedGridRow] = useState(null);
  const [selectedGridColumn, setSelectedGridColumn] = useState(null);
  const [selectedCheckPoint, setSelectedCheckPoint] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedCheckPointItem, setSelectedCheckPointItem] = useState(null);
  const [selectedCheckPointPart, setSelectedCheckPointPart] = useState(null);
  const [checkListOpen, setCheckListOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openPlanDetails, setOpenPlanDetails] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [checkPointHistory, setCheckPointHistory] = useState([]);
  const [checkListTitle, setCheckListTitle] = useState('');
  const [document, setDocument] = useState('');
  const [activities, setActivities] = useState([]);
  const [planActivities, setPlanActivities] = useState([]);
  const [planDetail, setPlanDetail] = useState(null);
  const [toggler, setToggler] = useState(false);
  const [lightBoxIndex, setLightBoxIndex] = useState(null);
  const [lightBoxImages, setLightBoxImages] = useState([]);
  const [completeConfirmOpen, setCompleteConfirmOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState({
    title: "sample",
    open: false,
    children: <div></div>,
    btn1: {
      text: "Close",
      onClick: () => { },
    },
  });
  useEffect(() => {
    // console.log('planActivity----', planActivity);
    if (planActivity.update?.data?._id) {
      dispatch(clearPlanActivityData(UPDATE_PLAN_ACTIVITY))
      setSelectedActivity(null)
      setOpen(false)
      setIsRectify(false);
      if (vin.vin) {
        // dispatch(getPlan(planId));
        dispatch(listPlanActivities({ workPlan: planId, vin: vin.vin, limit: 0, sortByAscending: '_id' }));
      }
    }
    if (planActivityLog.add?.data?._id) {
      dispatch(clearPlanActivityLogData(ADD_PLAN_ACTIVITY_LOG))
      setSelectedActivity(null)
      setOpen(false)
      setIsRectify(false);
      if (planId) {
        dispatch(getPlan(planId));
      }
    }
    if (planActivity?.add?.data?._id || planActivity?.add?.data?.length) {
      dispatch(clearPlanActivityData(ADD_PLAN_ACTIVITY))
      if (vin && planDetail?._id) {
        dispatch(listPlanActivities({ workPlan: planDetail?._id, vin: vin.vin, limit: 0, sortByAscending: '_id' }));
      }
    }
    if (planActivity?.list?.data) {
      dispatch(clearPlanActivityData(LIST_PLAN_ACTIVITY))
      if (planActivity?.list?.data.length && vin.vin) {
        setActivities(processPlanDetail(planActivity?.list?.data))
      } else if (vin.vin && planActivities.length) {
        dispatch(addPlanActivity(planActivities.map(a => {
          return {
            what: a.what,
            why: a.why,
            how: a.how,
            vin: vin.vin,
            workPlan: a.workPlan
          }
        })))
      }
    }
  }, [planActivity, planActivityLog]);
  useEffect(() => {
    // console.log('toggler', toggler, lightBoxImages)
    setLightBoxIndex(0)
    if (lightBoxImages.length)
      setToggler(true)
    else
      setToggler(false)
  }, [lightBoxImages]);
  useEffect(() => {
    if (objectCheckpoint.update?.data?._id) {
      dispatch(clearCheckpointData(UPDATE_OBJECT_CHECKPOINT));
      // console.log(objectCheckpoint)
      setCompleteConfirmOpen(false);
      setIsRectify(false);

      if (vin.vin) {
        setLoader(true)
        dispatch(listTechnicianPlanLogs({ technicianPlan: technicianPlanData._id, vin: vin.vin, limit: 1, sortByDescending: '_id' }))
      } else {
        dispatch(listTechnicianPlans({ workPlanId: planDetail?._id, userId: auth.data?._id, limit: 0 }));
      }
    }
  }, [objectCheckpoint]);
  useEffect(() => {
    // console.log('uploads', uploads, selectedCheckPoint, selectedActivity)
    if (
      uploads.list &&
      uploads.list.data &&
      uploads.list.data &&
      uploads.list.data.data &&
      uploads.list.data.data.length
    ) {
      setFiles(null);
      if (selectedCheckPoint !== null) {
        let grds = [...grids];
        let list1 = [];
        // console.log('grds---', grds);
        grds.map(a => {
          if (a.side === activeSide) {
            a.devideList.map((aa, jj) => {
              if (jj === selectedGridColumn) {
                list1 = aa.checkList;
              }
            })
          }
        });
        let chkPoint = list1[selectedCheckPoint];
        // console.log('list1---', list1, chkPoint);
        if (chkPoint.ok || chkPoint.nok) {
          // console.log('grdsgrdsgrdsgrds', list1, chkPoint);
          chkPoint.checked = true;
          // list1[selectedCheckPoint].ok = 1;
          chkPoint.images = uploads.list.data.data.map(a => a.key);
          // console.log(list1, chkPoint);
          list1[selectedCheckPoint] = chkPoint;
          let grds = [...grids];
          grds = grds.map(a => {
            if (a.side === activeSide) {
              a.devideList = a.devideList.map((aa, jj) => {
                if (jj === selectedGridColumn) {
                  aa.checkList = list1.map(chk => {
                    delete chk.status;
                    return chk;
                  });
                }
                return aa;
              })
            }
            return a;
          });
          // setGrids(grds);
          // setCheckList(processCheckList(list1));
          setTimeout(() => {
            // console.log('handleAction-----')
            let dta = chkPoint;
            dta.illustration = list.map(a => {
              return a;
            });
            dta.part = dta.part._id || dta.part;
            delete dta.status;
            delete dta.createdAt;
            if (isRectify)
              dta.rectifyComment = comment;
            else
              dta.comment = comment;
            dispatch(updateCheckpoint(dta, dta._id))
            setOpen(false)
            setComment("");
            setIsRectify(false);
          }, 50)
          // setTimeout(() => {
          //   console.log('handleAction-----')

          //   handleAction(selectedCheckPoint.ok ? true : false, selectedCheckPoint.nok ? false : true, false, true)
          // }, 100)
        } else {
          setUploadedFiles(uploads.list.data.data.map(a => a.key));
          setFiles(null)
        }
      } else {
        setUploadedFiles(uploads.list.data.data.map(a => a.key));
        setFiles(null)
      }

    }
  }, [uploads]);
  useEffect(() => {
    // console.log(uploadedFiles, selectedActivity)
    if (
      uploadedFiles.length
    ) {
      setFiles(null);
      handleAction(allOk ? true : false, allOk ? false : true, false, false);
    }
  }, [uploadedFiles]);
  useEffect(() => {
    let check = true;
    list?.map((itm) => {
      if (!itm.selected || itm.selected == "notOkImage") {
        check = false;
      }
    });
    setallOk(check);
  }, [list]);

  useEffect(() => {
    if (localCompany.companyId?.data) {
      setTimeout(() => {
        if (planId) {
          dispatch(getPlan(planId));
        }
      }, 100);
    }
  }, [localCompany]);
  useEffect(() => {
    if (project.detail?.data?._id) {
      dispatch(clearProjectData(GET_PROJECT));
      setProjectData(project.detail?.data);
    }
  }, [project]);
  useEffect(() => {
    if (list.length) {
      let selected = list.filter(a => a.selected);
      setAction(selected.length == list.length ? true : false)
    }
  }, [list]);
  useEffect(() => {
    let tplanList = [];
    if (technicianPlanData._id) {
      // console.log(technicianPlanData)
      let per = technicianPlanData.ok + technicianPlanData.nok + technicianPlanData.skip;
      // console.log(per);
      per = (per * 100) / technicianPlanData.quantity;
      // console.log(per);
      // setProgress(per);
      let tplan = technicianPlanData || {};
      if (tplan._id) {
        let tplanList = [
          { name: 'Total Amount to be Checked', value: tplan.quantity },
        ];
        if (tplan.target) {
          tplanList.push({ name: 'Target Amount to be Checked', value: tplan.target });
        }
        tplanList.push({
          name: 'NOK Total', value: (
            <Typography style={{ fontSize: '16px', fontWeight: 'bold', color: 'red' }} >{tplan.nok}</Typography>
          )
        });
        tplanList.push({
          name: 'Total OK', value: (
            <Typography style={{ fontSize: '16px', fontWeight: 'bold', color: 'green' }} >{tplan.ok}</Typography>

          )
        });
        tplanList.push({ name: 'Pending', value: tplan.pending });
        setPlanDetailList(tplanList);
      }
    }
  }, [technicianPlanData]);
  useEffect(() => {
    if (technicianPlan.list?.data?.length) {
      setLoader(false)
      dispatch(clearTechnicianPlanData(LIST_TECHNICIAN_WORK_PLAN))
      if (technicianPlan.list?.data[0]?.objectSide && technicianPlan.list?.data[0]?.objectSide.filter(a => a.devideList).length) {
        // console.log('################', technicianPlan.list?.data[0]?.objectSide.filter(a => a.devideList))
        setGrids(technicianPlan.list?.data[0]?.objectSide.filter(a => a.devideList));
        setActualGrids(technicianPlan.list?.data[0]?.objectSide.filter(a => a.devideList));
      }
      setTechnicianPlanData({
        ...technicianPlanData, ...(technicianPlan.list?.data.map(a => {
          a.quantity = a.workPlanId.quantity;
          a.skip = a.workPlanId.skip || a.skip || 0;
          a.ok = a.workPlanId.ok || a.ok || 0;
          a.nok = a.workPlanId.nok || a.nok || 0;
          a.pending = (a.workPlanId.quantity || a.quantity) - ((a.workPlanId.ok || a.ok) + (a.workPlanId.nok || a.nok) + (a.workPlanId.skip || a.skip));
          a.pending = a.pending < 0 ? 0 : a.pending;
          return a;
        })[0] || {})
      });

    }
    if (technicianPlan.update?.data?._id) {
      dispatch(clearTechnicianPlanData(UPDATE_TECHNICIAN_WORK_PLAN))

      // dispatch(getPlan(planId));
      setallOk(false);
      setLoading(false)
      setFiles(null);
      setUploadedFiles([]);
      setComment("");
      setOpen(false);
      setCheckListOpen(false);
      setlist([...list.map(a => { a.selected = ''; return a; })]);
      if (actualGrids && actualGrids.length) {
        let grds = [...actualGrids];
        grds = grds.map(a => {
          a.devideList = a.devideList.map((aa, jj) => {
            aa.checkList = (aa.checkList || []).filter(ft => ft).map(a => { a.checked = false; return a; })
            return aa;
          })
          return a;
        });
        setGrids(grds)
      }
      setAction(false);
      setAllCheckList([]);
      setTimeout(() => {
        if (vin) {
          dispatch(listTechnicianPlanLogs({ technicianPlan: technicianPlanData._id, vin: vin.vin, limit: 1, sortByAscending: '_id' }))
        } else {
          dispatch(listTechnicianPlans({ workPlanId: planDetail?._id, userId: auth.data?._id, limit: 0 }));
        }
      }, 50)
    }
  }, [technicianPlan]);
  useEffect(() => {
    if (sides && sides.length && !activeSide)
      setActiveSide(sides[0]);
  }, [sides]);
  useEffect(() => {
    if (grids && grids.length) {
      let allOk = true;
      let actn = false;
      let grds = [...grids];
      let aChkList = [];
      let checkPoint = {};
      let partTotal = 0, checkedPart = 0;
      grds = grds.map(a => {
        a.devideList = a.devideList.map((aa, jj) => {
          aa.checkList = aa.checkList.map(it => {
            if (it.part?._id) {
              partTotal = partTotal + 1;
              if (it.ok || it.complete)
                checkedPart = checkedPart + 1;
            }
            return it;
          })
          aChkList = [...aChkList, ...(aa.checkList || []).filter(ft => ft).map(it => { it.tag = aa.tag; return it; })];
          if ((aa.checkList || []).filter(ft => ft).filter(a => a.part?.illustration?.filter(ai => ai.selected === 'okImage').length === a.part?.illustration?.length).length !== (aa.checkList || []).length) {
            allOk = false;
          }
          if ((aa.checkList || []).filter(ft => ft).filter(a => a.part?.illustration?.filter(ai => ai.selected).length === a.part?.illustration?.length).length) {
            actn = true;
          }
          if (a.side == activeSide && jj == selectedGridColumn) {
            // console.log('aa.checkList', aa, aa.checkList.length)
            setCheckList(processCheckList(aa.checkList.filter(it => it.objectSideGrid === aa._id)))
            setSelectedCheckPointPart(null)
            setSelectedCheckPoint(null)
          }
          return aa;
        })
        return a;
      });
      let prg = ((checkedPart * 100) / partTotal)
      // console.log(checkedPart, partTotal)
      setProgress(prg);
      setallOk(allOk);
      setAction(actn);
      setAllCheckList(aChkList);
    }
  }, [grids]);
  useEffect(() => {
    // console.log('plan-----', plan);
    if (plan?.detail?.data?._id) {
      dispatch(clearPlanData(GET_WORK_PLAN))
      setPlanDetail({ ...(planDetail || {}), ...plan?.detail?.data })
      let act = plan?.detail?.data?.activity;
      setPlanActivities(act);
      setActivities(processPlanDetail(act))
      setSides(plan?.detail?.data?.grid?.images.map(a => a.side));
      setAllVehicleList(plan?.detail?.data?.vehicles?.map((a) => {
        a.name = a.vehicleId.vin;
        return a;
      }));
      // setGrids(plan?.detail?.data?.grid?.images);
      // setActualGrids(plan?.detail?.data?.grid?.images);
      if (plan?.detail?.data?.project?._id)
        dispatch(getProject(plan?.detail?.data?.project?._id));
      dispatch(listTechnicianPlans({ workPlanId: plan?.detail?.data?._id, userId: auth.data?._id, limit: 0 }));
      if (plan?.detail?.data?.part?._id)
        setlist(
          plan?.detail?.data?.part?.illustration?.map((itm) => {
            itm.selected = "";
            return itm;
          })
        );
      // setTechnicianPlanData({
      //   ...technicianPlanData, ...{
      //     skip: plan?.detail?.data?.skip || 0,
      //     quantity: plan?.detail?.data?.quantity || plan?.detail?.data?.part?.quantity || 0,
      //     target: plan?.detail?.data?.part?.target || 0,
      //     ok: plan?.detail?.data?.ok || 0,
      //     nok: plan?.detail?.data?.nok || 0,
      //     pending: plan?.detail?.data?.quantity - (plan?.detail?.data?.ok + plan?.detail?.data?.nok + plan?.detail?.data?.skip),
      //     _id: plan?.detail?.data?._id
      //   }
      // });
      setDocument(plan?.detail?.data?.eversignList[0]?.meta?.finalPdf);
      // console.log('vin && technicianPlanData=====', vin, technicianPlanData)
      if (vin && technicianPlanData._id) {
        dispatch(listTechnicianPlanLogs({ technicianPlan: technicianPlanData._id, vin: vin.vin, limit: 1, sortByDescending: '_id' }))
      }
    }
    if (plan.update?.data?._id) {
      dispatch(clearPlanData(UPDATE_WORK_PLAN))
      dispatch(getPlan(planId));
    }
  }, [plan]);
  useEffect(() => {
    // console.log('selectedCheckPoint', selectedCheckPoint)
    if (selectedCheckPoint !== null) {
      let grds = [...grids];
      let list1 = [];
      grds.map(a => {
        if (a.side === activeSide) {
          a.devideList.map((aa, jj) => {
            if (jj === selectedGridColumn) {
              list1 = aa.checkList;
            }
          })
        }
      })
      // console.log(list1[selectedCheckPoint].part, list1)
      setSelectedCheckPointPart(list1[selectedCheckPoint].part)
    }
  }, [selectedCheckPoint]);

  useEffect(() => {
    setSelectedGridColumn(null);
    setSelectedGridRow(null);
    setSelectedActivity(null);
    setSelectedGridColumn(null);
    setSelectedCheckPoint(null);
    setCheckList([]);
    setProgress(0)
    if (vin.vin && planDetail?._id) {
      setLoader(true)
      dispatch(listPlanActivities({ workPlan: planDetail?._id, vin: vin.vin, limit: 0, sortByAscending: '_id' }));
    }
    if (vin.vin && technicianPlanData._id) {
      setLoader(true)
      dispatch(listTechnicianPlanLogs({ technicianPlan: technicianPlanData._id, vin: vin.vin, limit: 1, sortByDescending: '_id' }))
    }
    if (!vin.vin) {
      setLoader(true)
      setActivities(processPlanDetail(planActivities))
      if (planDetail?._id)
        dispatch(listTechnicianPlans({ workPlanId: planDetail?._id, userId: auth.data?._id, limit: 0 }));
    }
  }, [vin]);
  useEffect(() => {
    setLoader(false)
    if (technicianPlanHistory?.list?.data && technicianPlanHistory?.list?.data[0]?.objectSide.length) {
      setGrids(technicianPlanHistory?.list?.data[0]?.objectSide.filter(a => a.devideList))
    } else {
      setGrids(actualGrids)
    }
  }, [technicianPlanHistory]);
  useEffect(() => {
    // console.log('files----', files)
  }, [files])
  useEffect(() => {
    // console.log('activeSide----', activeSide)
    setSelectedGridColumn(null);
    setSelectedGridRow(null);
    setSelectedActivity(null);
    setSelectedGridColumn(null);
    setSelectedCheckPoint(null);
    setCheckListTitle('');
    setCheckList([]);
  }, [activeSide])
  const handleAction = (ok, nok, skip, upload = false) => {
    // console.log(files, upload)
    if (upload && files && files.length) {
      setLoading(true)
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      dispatch(uploadFiles(formData));
    } else {
      let illustration = [...list];
      // console.log(illustration)
      let tplan = technicianPlanData;
      let data = {
        ok: tplan.ok,
        nok: tplan.nok,
        skip: tplan.skip,
        comment: comment || '',
        grids: grids,
        illustration: illustration.map(a => {
          return a;
        })
      };

      let aChkList = []
      grids.map(a => {
        a.devideList = a.devideList.map((aa, jj) => {
          aChkList = [...aChkList, ...(aa.checkList || []).filter(ft => ft).map(it => { it.tag = aa.tag; return it; })];

          return aa;
        })
        return a;
      });
      if (aChkList.filter(a => a.checked).length === aChkList.length) {
        if (aChkList.filter(a => a.ok).length === aChkList.length) {
          data = { ...data, ok: data.ok + 1, okItem: 1, nokItem: 0, skipItem: 0 };
        } else {
          data = { ...data, nok: data.nok + 1, nokItem: 1, okItem: 0, skipItem: 0 };
        }
      }
      if (vin) {
        data.vin = vin.vin
      }
      let checkPoint = {}, partTotal = 0, checkedPart = 0;
      data.objectSide = grids.map((a, i) => {
        if (a.side === activeSide) {
          a.devideList = a.devideList.map((aa, j) => {

            if (j === selectedGridColumn) {
              aa.checkList = (aa.checkList || []).filter(ft => ft).map((chk, ich) => {
                if (ich === selectedCheckPoint) {
                  checkPoint = chk;
                  delete data.comment;
                  if (isRectify)
                    checkPoint.rectifyComment = comment;
                  else
                    checkPoint.comment = comment;
                  if (uploadedFiles.length) {
                    checkPoint.images = uploadedFiles;
                  }
                  if (nok) {
                    checkPoint.nok = 1;
                    checkPoint.checked = true;
                  }
                  if (ok) {
                    checkPoint.ok = 1;
                    checkPoint.checked = true;
                  }
                  delete checkPoint.status;
                  delete checkPoint.history;
                  checkPoint.part = checkPoint.part._id;
                  return checkPoint;
                } else {
                  delete chk.status;
                  delete chk.history;
                  chk.part = chk.part._id;
                  return chk;
                }
              })
            }
            delete aa.status;
            (aa.checkList || []).filter(ft => ft).map((chk, ich) => {
              if (chk.part?._id) {
                partTotal = partTotal + 1;
                if (chk.ok || chk.complete || chk.nok)
                  checkedPart = checkedPart + 1;
              }
            });
            return aa;
          })
        }
        return a;
      });
      // console.log('=====', isRectify, selectedGridRow, selectedGridColumn, selectedCheckPoint, comment, data)

      // console.log(tplan, data, selectedActivity, checkPoint);

      if (selectedActivity) {
        let dt = data;
        if (isRectify) {
          dt.rectifyComment = comment;
          dt.comment = '';
        }
        else {
          dt.comment = comment;
          dt.rectifyComment = '';
          dt.nok = 1;
        }
        if (uploadedFiles.length) {
          dt.images = uploadedFiles;
        }
        if (!comment) {
          dt.ok = 0;
          dt.nok = 0;
        } else if (isRectify) {
          dt.ok = selectedActivity.ok;
          dt.nok = selectedActivity.nok;
        }
        delete dt.grids;
        delete dt.objectSide;
        if (!comment) {
          dt.workPlan = selectedActivity.workPlan;
          dt.planActivityId = selectedActivity._id;
          dispatch(addPlanActivityLog(dt));
        } else
          dispatch(updatePlanActivity(dt, selectedActivity._id));
        setComment("")
        setFiles(null)
      } else
        if (tplan._id) {
          if (checkPoint._id && checkPoint.vin && checkPoint.part) {
            delete checkPoint.createdAt;
            dispatch(updateCheckpoint(checkPoint, checkPoint._id));
            setComment("")
            setFiles(null)
            if (checkedPart == partTotal) {
              let ppv = allVehicleList.filter(a => a.vehicleId._id == vin._id);
              // console.log('ppv------', ppv);
              if (ppv.length) {
                dispatch(updateProjectPlanVehicle({ status: 'Completed' }, ppv[0]._id));
              }
            }
          } else {
            dispatch(updateTechnicianPlan(data, tplan._id));
            setComment("")
            setFiles(null)
            if (checkedPart == partTotal) {
              let ppv = allVehicleList.filter(a => a.vehicleId._id == vin._id);
              // console.log('ppv------', ppv);
              if (ppv.length) {
                dispatch(updateProjectPlanVehicle({ status: 'Completed' }, ppv[0]._id));
              }
            }
          }
        }
    }
    setOpen(false);

  }
  const handleCheckList = (itm, i, j) => {
    // console.log(vin);
    if (vin.vin) {
      setCheckList(processCheckList(itm.checkList))
      setSelectedGridRow(i);
      setSelectedGridColumn(j);
      setCheckListTitle(itm.tag);
      // setCheckListOpen(true)
    } else {
      setShowInfoDialog((old) => ({
        ...old,
        title: "VIN",
        open: true,
        children: <Typography>You have select VIN first</Typography>,
      }));
    }
  }
  const uploadSelectedFiles = (fileList, k = null) => {
    // console.log(fileList, k, selectedActivity)
    if (k)
      setSelectedCheckPoint(k);
    setLoading(true)
    let formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      formData.append("file", fileList[i]);
    }
    dispatch(uploadFiles(formData));
  }
  const processCheckList = (list) => {
    return list.map((item, key) => {
      item.partView = item.part?.name || '';
      if (item.ok || item.complete) {
        item.color = 'greenColor'
      } else if (item.nok && item.rectifyComment && !item.complete) {
        item.color = 'amberColor'
      } else if (item.nok && !item.rectifyComment && !item.complete) {
        item.color = 'redColor'
      } else {
        item.color = ''
      }
      if (item.part?._id) {
        // item.part?.illustration?.length !== item.part?.illustration?.filter(a => a.selected || a.checked).length ||
        item.status = (
          (!item.ok && !item.nok) ?
            <Button
              onClick={(e) => {
                setSelectedActivity(null);
                setSelectedCheckPoint(key);
                // setCheckListOpen(true)
              }}
              text="Select"
              variant="contained"
              color="primary"
            />
            :
            item.nok ?
              <>
                <Typography style={{ fontWeight: 'bold' }}>{item.complete ? 'OK' : 'Nok'}</Typography>
                {!item.complete ?
                  < Button
                    onClick={(e) => {
                      setSelectedActivity(null);
                      setSelectedCheckPoint(key);
                      setIsRectify(true)
                      setOpen(true)
                      // setCheckListOpen(true)
                    }}
                    text="Rectify"
                    variant="contained"
                    color="primary"
                    style={{ width: 'fit-content' }}
                  />
                  : null}
                {!item.complete ?
                  <><input
                    accept="image/*"
                    className={classes.input}
                    id={"contained-button-file-ok_" + key}
                    multiple
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      // console.log('key----', key, e)
                      setSelectedActivity(null);
                      uploadSelectedFiles(e.target.files, key)
                    }}
                  />
                    <label htmlFor={"contained-button-file-ok_" + key}>
                      <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text={"Upload"}>

                      </Button>
                    </label>
                  </>
                  : null}
                {item.history.filter(a => a.comment || a.rectifyComment || a.images.length).length ?
                  <Button
                    onClick={(e) => {
                      setSelectedActivity(null);
                      setCheckPointHistory(item.history);
                      setOpenHistory(true)
                      // setCheckListOpen(true)
                    }}
                    text="History"
                    variant="contained"
                    color="primary"
                    style={{ width: 'fit-content' }}
                  />
                  : null}
                {item.history.filter(a => a.rectifyComment).length && !item.complete ?
                  <Button
                    onClick={(e) => {
                      setSelectedActivity(null);
                      setSelectedCheckPointItem(item)
                      setCompleteConfirmOpen(true)
                    }}
                    text="Complete"
                    variant="contained"
                    color="primary"
                    style={{ width: 'fit-content' }}
                  />
                  : null}
              </> : <>
                <Typography style={{ fontWeight: 'bold' }}>OK</Typography>
                {item.images && item.images.length ? null : <><input
                  accept="image/*"
                  className={classes.input}
                  id={"contained-button-file-ok_" + key}
                  multiple
                  type="file"
                  style={{ display: 'none' }}

                  onChange={(e) => {
                    // console.log('checkPoint----', key)
                    setSelectedActivity(null);
                    setSelectedCheckPoint(key);
                    uploadSelectedFiles(e.target.files, key)
                  }}
                />
                  <label htmlFor={"contained-button-file-ok_" + key}>
                    <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text="Upload">

                    </Button>
                  </label>
                </>
                }
                {item.history.filter(a => a.comment || a.rectifyComment || a.images.length).length ?
                  <Button
                    onClick={(e) => {
                      setSelectedActivity(null);
                      setCheckPointHistory(item.history);
                      setOpenHistory(true)
                    }}
                    text="History"
                    variant="contained"
                    color="primary"
                    style={{ width: 'fit-content' }}
                  />
                  : null}
              </>
        );
      } else
        item.status = !item.ok && !item.nok ? (
          <div>
            <Button
              onClick={(e) => {
                setSelectedActivity(null);
                let list1 = [...list];
                // console.log(list1, list1[key]);
                list1[key].checked = true;
                list1[key].ok = 1;
                let grds = [...grids];
                grds = grds.map(a => {
                  if (a.side === activeSide) {
                    a.devideList = a.devideList.map((aa, jj) => {
                      if (jj === selectedGridColumn) {
                        aa.checkList = list1.map(chk => {
                          delete chk.status;
                          return chk;
                        });
                      }
                      return aa;
                    })
                  }
                  return a;
                });
                // setGrids(grds);
                // setCheckList(processCheckList(list1));
                setTimeout(() => {
                  handleAction(allOk ? true : false, allOk ? false : true, false, true)
                }, 100)
              }}
              text="Ok"
              variant="contained"
              color="primary"
            />
            <Button
              onClick={(e) => {
                setSelectedActivity(null);
                setSelectedCheckPoint(key);
                setCheckListOpen(true)
              }}
              text="Nok"
              variant="contained"
              color="primary"
            />
          </div>
        ) : item.ok ? <>
          <Typography style={{ fontWeight: 'bold' }}>OK</Typography>
          {item.images && item.images.length ? null : <><input
            accept="image/*"
            className={classes.input}
            id={"contained-button-file-ok_" + key}
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              // console.log('checkPoint----', key)
              setSelectedActivity(null);
              uploadSelectedFiles(e.target.files, key)
            }}
          />
            <label htmlFor={"contained-button-file-ok_" + key}>
              <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text="Upload">

              </Button>
            </label>
          </>
          }
        </> : <Grid style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <Typography style={{ width: 50, float: 'left' }}>Nok</Typography>
          {item.rectifyComment ? null :
            <Button
              onClick={(e) => {
                // console.log('checkPoint----', key)
                setSelectedActivity(null);
                setSelectedCheckPoint(key);
                setIsRectify(true)
                setOpen(true)
                // setCheckListOpen(true)
              }}
              text="Rectify"
              variant="contained"
              color="primary"
              style={{ width: 'fit-content' }}
            />
          }
        </Grid>;
      // console.log(item)
      return item;
    });
  }
  const processPlanDetail = (list) => {
    // console.log('list------', list)
    return list.map((item, key) => {
      item.partView = item.part?.name || '';
      if (item.ok || item.complete) {
        item.color = 'greenColor'
      } else if (item.nok && item.rectifyComment && !item.complete) {
        item.color = 'amberColor'
      } else if (item.nok && !item.rectifyComment && !item.complete) {
        item.color = 'redColor'
      } else {
        item.color = ''
      }
      if (item.ok || item.nok) {
        // item.part?.illustration?.length !== item.part?.illustration?.filter(a => a.selected || a.checked).length ||
        item.action = item.vin ? (
          item.nok ?
            <>
              <Typography style={{ fontWeight: 'bold' }}>{item.complete ? 'OK' : 'Nok'}</Typography>
              {!item.complete ?
                < Button
                  onClick={(e) => {
                    setSelectedActivity(item);
                    setIsRectify(true)
                    setOpen(true)
                    // setCheckListOpen(true)
                  }}
                  text="Rectify"
                  variant="contained"
                  color="primary"
                  style={{ width: 'fit-content' }}
                />
                : null}
              {!item.complete ?
                <><input
                  accept="image/*"
                  className={classes.input}
                  id={"contained-activity-button-file-ok_" + key}
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    // console.log('key----', key, e)
                    setSelectedActivity(item)
                    setTimeout(() => {
                      uploadSelectedFiles(e.target.files)
                    }, 100);
                  }}
                />
                  <label htmlFor={"contained-activity-button-file-ok_" + key}>
                    <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text={"Upload"}>

                    </Button>
                  </label>
                </>
                : null}
              {item.history.filter(a => a.comment || a.rectifyComment || a.images.length).length ?
                <Button
                  onClick={(e) => {
                    setCheckPointHistory(item.history);
                    setOpenHistory(true)
                    // setCheckListOpen(true)
                  }}
                  text="History"
                  variant="contained"
                  color="primary"
                  style={{ width: 'fit-content' }}
                />
                : null}
              {item.history.filter(a => a.rectifyComment).length && !item.complete ?
                <Button
                  onClick={(e) => {
                    setSelectedActivity(item)
                    setCompleteConfirmOpen(true)
                  }}
                  text="Complete"
                  variant="contained"
                  color="primary"
                  style={{ width: 'fit-content' }}
                />
                : null}
            </> : <>
              <Typography style={{ fontWeight: 'bold' }}>OK</Typography>
              {item.images && item.images.length ? null : <><input
                accept="image/*"
                className={classes.input}
                id={"contained-activity-button-file-ok_" + key}
                multiple
                type="file"
                style={{ display: 'none' }}

                onChange={(e) => {
                  setSelectedActivity(item)
                  setTimeout(() => {
                    uploadSelectedFiles(e.target.files)
                  }, 100);
                }}
              />
                <label htmlFor={"contained-activity-button-file-ok_" + key}>
                  <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text="Upload">

                  </Button>
                </label>
              </>
              }
              {item.history.filter(a => a.comment || a.rectifyComment || a.images.length).length ?
                <Button
                  onClick={(e) => {
                    setCheckPointHistory(item.history);
                    setOpenHistory(true)
                  }}
                  text="History"
                  variant="contained"
                  color="primary"
                  style={{ width: 'fit-content' }}
                />
                : null}
            </>
        ) : null;
      } else
        item.action = !item.ok && !item.nok && (item.vin || vin._id) ? (
          <div>
            <Button
              onClick={(e) => {
                dispatch(updatePlanActivity({ ok: 1 }, item._id))
              }}
              text="Ok"
              variant="contained"
              color="primary"
            />
            <Button
              onClick={(e) => {
                setSelectedActivity(item);
                setOpen(true)
              }}
              text="Nok"
              variant="contained"
              color="primary"
            />
          </div>
        ) : item.ok ? <>
          <Typography style={{ fontWeight: 'bold' }}>OK</Typography>
          {item.images && item.images.length ? null : <><input
            accept="image/*"
            className={classes.input}
            id={"contained-activity-button-file-ok_" + key}
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              setSelectedActivity(item)
              setTimeout(() => {
                uploadSelectedFiles(e.target.files)
              }, 100);
            }}
          />
            <label htmlFor={"contained-activity-button-file-ok_" + key}>
              <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text="Upload">

              </Button>
            </label>
          </>
          }
        </> : item.vin ? <Grid style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <Typography style={{ width: 50, float: 'left' }}>Nok</Typography>
          {item.rectifyComment ? null :
            <Button
              onClick={(e) => {
                setSelectedCheckPoint(key);
                setIsRectify(true)
                setOpen(true)
                // setCheckListOpen(true)
              }}
              text="Rectify"
              variant="contained"
              color="primary"
              style={{ width: 'fit-content' }}
            />
          }
        </Grid> : null;
      // console.log(item)
      return item;
    });
  }
  const processHistory = (list) => {
    return list.filter(a => a.comment || a.rectifyComment || a.images.length).map(a => {
      a.commentView = a.comment || 'NA';
      a.rectifyCommentView = a.rectifyComment || 'NA';
      a.image = <span onClick={() => {
        setLightBoxImages(a.images.map(a => axios.defaults.baseURL + '/file/' + a));
      }} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{a.images.length}</span>;
      a.dateView = new Date(a.createdAt).toLocaleString()
      return a;
    })
  }
  return (
    <Grid container className="technician-action">
      <Grid item sm={12}>
        <h2>
          <IconButton
            color="primary"
            onClick={() => history.goBack()}
            icon=<ArrowBack />
            props={{
              style: {}
            }}
          ></IconButton>
          {projectData?.name} Work Plan - {planDetail?.name}
        </h2>
      </Grid>
      <Grid item container>
        <Grid item sm={6} style={{ borderRight: '1px solid', position: 'relative' }}>
          <Backdrop
            id="globle-loader-1"
            className="show-loader"
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
            open={loader}
          // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container>
            <ProjectDetailComponent data={projectData} show={[]} margin="0" additional={
              <>
                <Grid item sm={8} style={{ display: "flex" }}>
                  <Typography style={{ fontWeight: "bold", }}>
                    PDSL Project No. :
                  </Typography>
                  <Typography>{projectData?.projectNo}</Typography>
                </Grid>
                <Grid item sm={4} style={{ display: "flex" }}>
                  <Typography style={{ fontWeight: "bold", }}>
                    Rev :
                  </Typography>
                  <Typography>{projectData?.rev}</Typography>
                </Grid>
                <Grid item sm={12} style={{ display: "flex" }}>
                  <Typography style={{ fontWeight: "bold", }}>
                    SAP/Customer Ref :
                  </Typography>
                  <Typography>{projectData?.sapNo}</Typography>
                </Grid>
              </>
            }></ProjectDetailComponent>
            {grids && grids.length ?
              <>
                <Grid item spacing={2} container style={{ marginRight: 20, marginBottom: 20 }}>
                  <Grid item sm={8}>
                    {grids.filter(a => a.side === activeSide).map((a, i) => (
                      <Grid item container>
                        {/* <Grid item sm={3} style={{ textAlign: 'center' }}>
                      <Typography>{a.side}</Typography>
                    </Grid> */}
                        <Grid item sm={12} style={{ overflowX: 'auto' }}>
                          <Grid item
                            // ref={gridRef}
                            style={{
                              display: 'grid',
                              gridTemplateColumns: `repeat(${a.column}, 0fr)`,
                              gridTemplateRows: `repeat(${a.row}, 1fr)`,
                              gridGap: '5px',
                            }}
                          >

                            {a.devideList.map((itm, j) => (
                              <Grid item style={{ position: 'relative' }}>
                                <img src={(itm.image || '').indexOf('base64') != -1 ? itm.image : axios.defaults.baseURL + '/file/' + itm.image} style={{ cursor: 'pointer' }} onClick={() => { handleCheckList(itm, i, j) }} className={(itm.checkList || []).filter(ft => ft).filter(aaa => aaa?.checked).length === (itm.checkList || []).length ? 'green-border' : 'red-border'} />
                                <label onClick={() => { handleCheckList(itm, i, j) }} style={{
                                  position: 'absolute', left: '1rem',
                                  top: '2rem', fontWeight: 'bold', fontSize: 'xx-large', color: (itm.checkList || []).filter(ft => ft).filter(aaa => aaa?.checked).length === (itm.checkList || []).length ? 'green' : 'red', cursor: 'pointer'
                                }}>{itm.tag}</label>
                              </Grid>
                            ))}

                          </Grid>

                        </Grid>


                      </Grid>
                    ))}
                  </Grid>
                  <Grid item sm={4}>
                    <Grid item sm={12} spacing={2} style={{ marginBottom: 20 }}>
                      <AutoComplete
                        variant="filled"
                        label="Vin"
                        color="secondary"
                        value={allVehicleList.find(a => a._id == vin._id)}
                        name="Vin"
                        // select={true}
                        options={(allVehicleList || [])}
                        helperText=""
                        getOptionDisabled={(option) => option.status === 'Completed'}

                        onChange={(e, newValue) => {
                          // console.log(newValue)
                          newValue = newValue || {};
                          if (newValue.vehicleId)
                            setVin({
                              ...vin, ...{
                                _id: newValue.vehicleId._id || '', vin: newValue.vehicleId.vin || ''
                              }
                            });
                          else
                            setVin({})
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12} style={{ marginBottom: 20 }}>
                      <TextField
                        variant="filled"
                        label="Side"
                        color="secondary"
                        value={activeSide}
                        name="grid"
                        options={(sides || []).map(a => {
                          return {
                            value: a,
                            label: a,
                          };
                        })}
                        select={true}
                        // helperText=""
                        // isError={errors.grid}
                        onChange={(e, newValue) => {

                          setActiveSide(e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>

                  </Grid>
                  <Divider style={{ margin: '25px 0', width: '100%' }} />
                </Grid>
                {selectedCheckPointPart?._id ?
                  <Grid item spacing={2} container style={{ marginRight: 20 }}>
                    <Typography item style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: 'bold', marginBottom: '1rem' }}>Photo/Illustration</Typography>
                    <Grid item sm={12} className={`${classes.align}`}>
                      <Grid item style={{ textAlign: "center", position: 'relative' }} className="main-image">
                        <img
                          src={axios.defaults.baseURL + "/file/" + selectedCheckPointPart?.image}
                          width={150}
                          height={150}
                        />
                        <KeyboardBackspace style={{ position: 'absolute', top: '50px', fontSize: '55px', color: 'green' }} />
                        <Typography style={{ position: 'absolute', top: '45px', marginLeft: '3.5rem' }} variant="span">
                          {selectedCheckPointPart?.name}<br />
                          {selectedCheckPointPart?.partNo}<br />
                          (Please See Samples)
                        </Typography>
                      </Grid>
                      <Grid item container style={{ justifyContent: 'center' }}>
                        <Grid item sm={6} style={{ textAlign: "center" }}>
                          <Grid item container style={{ padding: 0, justifyContent: 'center', }}>
                            <Grid
                              item
                              style={{ background: "red", fontWeight: "bold", padding: '10px 30px', color: '#fff' }}
                            >

                              Not Ok Image (Example)
                            </Grid>
                          </Grid>
                          <Grid item container spacing={2} style={{ marginTop: "1rem", justifyContent: 'center', }}>
                            {selectedCheckPointPart?.illustration?.map((itm, i) => (
                              <Grid item sm={12}>
                                <img
                                  src={axios.defaults.baseURL + "/file/" + itm.notOkImage}
                                  width={150}
                                  height={150}
                                  style={{ marginBottom: '2rem' }}
                                  className={
                                    !itm.selected
                                      ? `${classes.notSelected}`
                                      : itm.selected == "notOkImage"
                                        ? `${classes.nokselected}`
                                        : `${classes.notSelected}`
                                  }
                                  onClick={() => {
                                    if (progress < 100) {
                                      let prt = selectedCheckPointPart;
                                      prt.illustration[i].selected = "notOkImage";
                                      setSelectedCheckPointPart({ ...selectedCheckPointPart, ...prt });
                                    }
                                  }}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item sm={6} style={{ textAlign: "center" }}>
                          <Grid item container style={{ padding: 0, justifyContent: 'center', }}>
                            <Grid
                              item
                              style={{ background: "green", fontWeight: "bold", padding: '10px 30px', color: '#fff' }}
                            >

                              Ok Image (Example)
                            </Grid>
                          </Grid>
                          <Grid item container spacing={2} style={{ marginTop: "1rem", justifyContent: 'center' }}>
                            {selectedCheckPointPart?.illustration?.map((itm, i) => (
                              <Grid item sm={12}>
                                <img
                                  src={axios.defaults.baseURL + "/file/" + itm.okImage}
                                  width={150}
                                  height={150}
                                  style={{ marginBottom: '2rem' }}
                                  className={
                                    !itm.selected
                                      ? `${classes.notSelected}`
                                      : itm.selected == "okImage"
                                        ? `${classes.selected}`
                                        : `${classes.notSelected}`
                                  }
                                  onClick={() => {
                                    if (progress < 100) {
                                      let prt = selectedCheckPointPart;
                                      prt.illustration[i].selected = "okImage";
                                      setSelectedCheckPointPart({ ...selectedCheckPointPart, ...prt });
                                    }
                                  }}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  : null}
              </>
              :
              null
            }
          </Grid>
        </Grid>
        <Grid item sm={6} style={{ paddingLeft: "1rem", position: 'relative', minHeight: '30rem' }}>
          <Grid item container>

            <Grid item xs={12} style={{ display: "flex", marginBottom: 10 }}>
              <Typography item style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Batch/Production Date(If Applicable):
              </Typography>
              <Typography item>{planDetail?.batch}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", marginBottom: 10 }}>
              <Typography item style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Delivery/Advice No(If Applicable):
              </Typography>
              <Typography item>{moment(projectData.expectedEndDate).format('YYYY-MM-DD')}</Typography>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 10, }}>
              <Grid item xs={12} style={{}}>
                <Typography item style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                  Plan details
                </Typography>
              </Grid>
              {/* <Button variant="contained" color="primary" onClick={() => setOpenPlanDetails(true)} text='View' /> */}
              <ActionTable
                header={TABLE_HEADER_ACTIVITY}
                data={activities}
                actions={false}
                hazardColumn={false}
                controlMeasureColumn={false}
                color={true}
                style={{ maxHeight: 275 }}
              />
            </Grid>

            <Grid item xs={12} style={{}}>
              <Grid item sm={12} style={{}}>
                <Typography item style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                  Task Progress
                </Typography>
              </Grid>
              <Grid item xs={12} style={{}}>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '2rem' }}>
                {grids && grids.length && vin.vin ?
                  <>
                    <Grid item sm={12} style={{}}>
                      <Typography item style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                        VIN {vin.vin ? '(' + vin.vin + ')' : ''} {checkListTitle} checklist
                      </Typography>
                    </Grid>
                    <ActionTable
                      header={CHECKLIST_TABLE_HEADER}
                      data={checkList}
                      actions={false}
                      hazardColumn={false}
                      controlMeasureColumn={false}
                      color={true}
                    />
                    <Grid item className={classes.btn}>
                      <Grid item>
                        {selectedCheckPoint != null && selectedCheckPointPart?._id ?
                          <Button
                            text={selectedCheckPointPart?.illustration.filter(a => a.selected == 'okImage').length === selectedCheckPointPart?.illustration.length ? "Ok" : "Not Ok"}
                            variant="contained"
                            color="primary"
                            onClick={() => { setFiles(null); setTimeout(() => { selectedCheckPointPart?.illustration.filter(a => a.selected === 'okImage').length === selectedCheckPointPart?.illustration.length ? handleAction(selectedCheckPointPart?.illustration.filter(a => a.selected == 'okImage').length === selectedCheckPointPart?.illustration.length ? true : false, selectedCheckPointPart?.illustration.filter(a => a.selected == 'okImage').length === selectedCheckPointPart?.illustration.length ? false : true, false, true) : setOpen(true) }, 100) }}
                            style={{ marginBottom: "1rem", width: "14rem" }}
                            disabled={progress >= 100 || selectedCheckPointPart?.illustration.length !== selectedCheckPointPart?.illustration.filter(a => a.selected).length}
                          /> :
                          null}
                      </Grid>

                    </Grid>
                  </>
                  :
                  null
                }
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        {toggler ?
          <Lightbox
            images={lightBoxImages.map(a => ({ url: a }))}
            onClose={(instance) => {
              // console.log('close', instance);
              setLightBoxImages([])
            }}
          />
          : null}

      </Grid>
      <div key={"div-2"}>
        {DIALOGS.CONFIRM_DIALOG({
          title: 'Compelte Confirmation',
          text: 'Are you sure you want to complete?',
          open: completeConfirmOpen,
          setOpen: setCompleteConfirmOpen,
          btn1: {
            text: "No",
            onClick: () => { setCompleteConfirmOpen(false) },
          },
          btn2: {
            text: "Yes",
            onClick: () => {
              if (selectedActivity) {
                dispatch(updatePlanActivity({ complete: true }, selectedActivity._id));
                setSelectedActivity(null);
                setCompleteConfirmOpen(false)
                setFiles(null);
              } else {
                dispatch(updateCheckpoint({ complete: true }, selectedCheckPointItem._id));
                setSelectedCheckPoint(null);
                setCompleteConfirmOpen(false)
                setFiles(null);
              }
            },
          },
          container: `${classes.paddingX}`,
        })}
        {DIALOGS.INFO_DIALOG({
          ...showInfoDialog,
          setOpen: setShowInfoDialog,
          container: `${classes.paddingX}`,
        })}
        <Dialog
          open={open}
          // onClose={setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{isRectify ? "Rectify Comment" : ((technicianPlanData?.nok === 2 ? 'TIP Level 3' : technicianPlanData?.nok === 3 ? 'TIP Level 4' : technicianPlanData?.nok > 3 ? 'TIP Level 5' : '') + (technicianPlanData.quantity - (technicianPlanData.ok + technicianPlanData.nok) == 1 ? "Completion Comment" : " Defect Description"))}</DialogTitle>
          <DialogContent className="illustration-inspector">
            <>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  label={(isRectify ? "Rectify Description" : " Defect Description")}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} >
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => setFiles(e.target.files)}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text="Upload">

                  </Button>
                </label>

              </Grid>

            </>

          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                if (isRectify) {
                  if (selectedActivity) {
                    // setComment("");
                    // setIsRectify(false);
                    // dispatch(updatePlanActivity({ rectifyComment: comment }, selectedActivity._id))
                    handleAction(allOk ? true : false, allOk ? false : true, false, true)

                    setOpen(false)
                  } else {
                    let grds = [...grids];
                    let list1 = [];
                    // console.log('grds---', grds);
                    grds.map(a => {
                      if (a.side === activeSide) {
                        a.devideList.map((aa, jj) => {
                          if (jj === selectedGridColumn) {
                            list1 = aa.checkList;
                          }
                        })
                      }
                    })
                    // console.log('handleAction-----')
                    let dta = list1[selectedCheckPoint];
                    handleAction(allOk ? true : false, allOk ? false : true, false, true)
                    // dispatch(updateCheckpoint({ rectifyComment: comment }, dta._id))
                    // setOpen(false)
                    // setComment("");
                    // setIsRectify(false);
                  }
                } else
                  handleAction(allOk ? true : false, allOk ? false : true, false, true)

              }}
              text="Submit"
              variant="contained"
              color="primary"
              disabled={!comment && allCheckList.filter(a => a.part?.illustration?.filter(aa => aa.selected).length === a.part?.illustration?.length).length == 0}
            />
          </DialogActions>
        </Dialog>
        <Dialog
          // onClose={() => setCheckListOpen(false)} 
          open={checkListOpen}>
          <DialogTitle>Grid {checkListTitle} checklist</DialogTitle>
          <DialogContent className="illustration-inspector">
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  label={"Defect Description"}
                  value={comment}
                  multiline
                  rows={5}
                  fullWidth
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} >
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => setFiles(e.target.files)}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} text="Upload">

                  </Button>
                </label>

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'block' }}>
            <Button
              onClick={(e) => {
                setCheckListOpen(false)
              }}
              text="Cancel"
              variant="contained"
              color="default"
              style={{ float: 'left' }}
            />
            <Button
              onClick={(e) => {

                handleAction(false, true, false, true)
              }}
              text="Submit"
              variant="contained"
              color="primary"
              style={{ float: 'right' }}
              disabled={!comment && allCheckList.filter(a => a.checked).length == 0}
            />
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => setOpenPlanDetails(false)}
          maxWidth='md'
          open={openPlanDetails}>
          <DialogTitle>Plan details
            <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={() => setOpenPlanDetails(false)} icon={<Close />} props={{ style: { float: 'right' } }}>

            </IconButton>
          </DialogTitle>
          <DialogContent className="illustration-inspector">
            <Grid item xs={12} container spacing={2} style={{ minWidth: 300 }}>
              <ActionTable
                header={TABLE_HEADER_ACTIVITY}
                data={activities}
                actions={false}
                controlMeasureColumn={false}
                hazardColumn={false}
                color={true}
              />
            </Grid>
          </DialogContent>

        </Dialog>
        <Dialog
          onClose={() => setOpenHistory(false)}
          maxWidth='md'
          open={openHistory}>
          <DialogTitle>History
            <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={() => setOpenHistory(false)} icon={<Close />} props={{ style: { float: 'right' } }}>

            </IconButton>
          </DialogTitle>
          <DialogContent className="illustration-inspector">
            <Grid item xs={12} container spacing={2} style={{ minWidth: 300 }}>
              <ActionTable
                header={TABLE_HEADER_HISTORY}
                data={processHistory(checkPointHistory || [])}
                actions={false}
                controlMeasureColumn={false}
                hazardColumn={false}
                color={true}
              />
            </Grid>
          </DialogContent>

        </Dialog>

      </div>
    </Grid >
  );
}

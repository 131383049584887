import React from 'react';
import './css/add_button.css';

const AddButton = () => {
  return (
    <button className='btn btn-base-class rounded-circle'>
      <i className="fa fa-plus" aria-hidden="true"/>
    </button>
  )
}

export default AddButton;

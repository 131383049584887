import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Tooltip } from "../../index";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  BRM,
  PDSLADMIN,
} from "../../../../components/Projects/AllProjects/CONSTANTS";
import { useSelector } from "react-redux";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: "bold",
    fontSize: 12,
    overflowX: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
  },
  body: {
    fontSize: 13,
    overflowX: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    // maxWidth: "20px",
  },

}))(TableCell);

// const SecondHeaderStyledTableCell = withStyles((theme) => ({
//   head: {
//     fontWeight: "bold",
//     fontSize: 12,
//   },
// }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(even)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);
const useStyles = makeStyles({
  selectedRow: {
    backgroundColor: "#e3e3e3",
  },
});

const Index = ({
  data = [],
  onEdit = null,
  editPrefix = "/edit",
  onDelete = null,
  header = {},
  actions = true,
  onLogin = null,
  showDelete = true,
  startButton = false,
  startBtn = false,
  addRow = false,
  onAddRow = null,
  editBtn = true,
  onStart = null,
  addRowText = '',
  editText = '',
  projectTable = false,
  selectedRowFunc = null,
  addRowMarginRight = null
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const getKeys = (obj) => Object.keys(obj);
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const [selectedRow, setselectedRow] = useState([]);

  useEffect(() => {
    if (data.length) {
      setselectedRow(
        data.map((itm) => {
          return false;
        })
      );
    }
  }, [data]);

  const renderHeader = () => {
    let keys = getKeys(header);
    return keys.map((key, index) => (
      <StyledTableCell
        key={index}
        align={header[key]?.align || `center`}
        style={{ width: header[key]?.width || "auto" }}
        className={header[key]?.className}
      >
        {header[key]?.data}
      </StyledTableCell>
    ));
  };

  const renderRows = () => {
    let keys = getKeys(header);
    // console.log(data)
    return data.map((item, i) => (
      <StyledTableRow
        key={i}
        onClick={() => {
          if (projectTable) {
            // console.log(selectedRow)
            setselectedRow(
              selectedRow.map((itm, index) => {
                if (i == index) {
                  selectedRow[index] = !selectedRow[index];
                  // console.log(item);
                  if (selectedRow[index]) {
                    selectedRowFunc(item);
                  } else {
                    selectedRowFunc(null)
                  }
                } else {
                  selectedRow[index] = false;
                }
                return selectedRow[index];
              })
            );
          }
        }}
        className={selectedRow[i] ? classes.selectedRow + (item.revProject?' revProject':'') : (item.revProject?' revProject':'')}
      >
        {renderDataCells(item, keys, i)}
      </StyledTableRow>
    ));
  };

  const renderDataCells = (row, keys, i) => {
    let dataCells = keys.map((key, j) => (
      <StyledTableCell
        key={j}
        align={header[key]?.align || `center`}
        style={header[key]?.style ? header[key]?.style : { maxWidth: header[key]?.width || "auto" }}
        // onClick = {() => {
        //   if(key == 'projectNo'){
        //     // console.log(row)
        //     history.push(`/project/${row._id}`)
        //   }
        // }}
        className={header[key]?.className}
      >
        <Grid style={{
          display: "flex", flexDirection: "column", whiteSpace: "normal",
          wordWrap: "break-word"
        }}>
          {renderComponent(header[key], row, key)}
          {key == "projectNo" ? (
            <Button
              onClick={() => {
                history.push(`/project/${row._id}`);
              }}
              text={"Details"}
              variant="contained"
              color="primary"
            />
          ) : null}
        </Grid>
      </StyledTableCell>
    ));
    let action = (
      <StyledTableCell align="center" style={{ maxWidth: "220px" }}>
        {/* {console.log('->',row)} */}
        <div>
          {startBtn ? (
            <Button
              onClick={(e) => {
                onStart(e, i, row);
              }}
              text={
                row.status === "Awaiting to Start Assessment" ||
                  row.status === "Awaiting to Start Audit"
                  ? "Start"
                  : "Detail"
              }
              variant="contained"
              color="primary"
              style={{ marginBottom: ".5rem" }}
            />
          ) : (
            <>
              {row.startButtonTitle ? (
                <Tooltip
                  title={row.startButtonTitle || "Start"}
                  placement="bottom"
                  Component={() => (
                    <Button
                      onClick={(e) => {
                        onStart(e, i, row);
                      }}
                      text="Start"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: ".5rem", padding: '0.4rem 1.3rem' }}

                    />
                    // {// marginLeft: '5.3rem',*/}
                  )}
                />
              ) : null}
              {editBtn && (typeof row.edit !== 'undefined' ? row.edit : true) ? (
                <Button
                  onClick={(e) => {
                    onEdit(i, row);
                  }}
                  text={editText || "Edit"}
                  variant="contained"
                  color="secondary"
                  style={{ marginBottom: ".5rem" }}
                />
              ) : null}
              {row.name !==
                (auth.data?.role?.name == PDSLADMIN ? PDSLADMIN : BRM) &&
                row._id !== auth.data?._id &&
                showDelete ? (
                <DeleteButton
                  onClick={(e) => {
                    onDelete(i, e, row);
                  }}
                  text="Delete"
                  variant="contained"
                  style={{ marginBottom: ".5rem" }}
                />
              ) : null}
            </>
          )}
        </div>
      </StyledTableCell>
    );
    return (
      <>
        {dataCells}
        {actions && action}
      </>
    );
  };

  const renderComponent = (column, row, key) => {
    // console.log(column, row, key)
    if (!column?.component)
      if (row[key] === 0) return row[key];
      else if (!row || !row[key]) return "";
      else return row[key];
    // return ''
    if (!row[key]) return "";
    switch (column?.component) {
      case "Tooltip":
        return (
          <Tooltip
            {...column.props}
            title={row[column.props.title]?.map((item, i) => {
              return <div key={i}>{item}</div>;
            })}
            Component={() => <Typography noWrap={true}>{row[key]}</Typography>}
          />
        );
      case "img":
        return row[key] ? (
          <img
            width="100"
            height="100"
            className={classes.img}
            src={row[key]}
            alt={"image-" + key}
          />
        ) : null;
      case "link":
        return (
          <>
            <Button
              onClick={(e) => {
                onLogin(row, column);
              }}
              text={column.label}
              variant="contained"
              color="primary"
              style={{ marginBottom: ".5rem" }}
            />
          </>
        );
      default:
        break;
    }
  };

  return (
    <TableContainer component={Paper}>
      {/* <Table fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}> */}
      <Table>
        <TableHead>
          <TableRow>
            {renderHeader()}
            {actions && (
              <StyledTableCell align="center" style={{ width: "200px" }}>
                Action
              </StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRows()}
          {addRow ? (
            <StyledTableRow key={"add-row"}>
              <StyledTableCell
                key={"add-row-cell"}
                align={`right`}
                style={{ maxWidth: "auto" }}
                colSpan={getKeys(header).length + 1}
              >
                <Button
                  onClick={(e) => {
                    onAddRow(e);
                  }}
                  text={addRowText || "Add Row"}
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: ".5rem", marginRight: addRowMarginRight || "8rem" }}
                />
              </StyledTableCell>
            </StyledTableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Index;

import {
  LIST_ACCOUNT,
  GET_ACCOUNT,
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT
} from '../action_types';

const initialState = {
  list: {
    pagination: {},
    totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false
  }
}

const account = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_ACCOUNT:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload
        }
      };
    case GET_ACCOUNT:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload
        }
      };
    case ADD_ACCOUNT:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          isLoading: false,
          hasError: payload.hasError,
        }
      }
    case UPDATE_ACCOUNT:
      let updatedItem = payload.data;
      let tempList = [...state.list.data]
      tempList.forEach((item, i) => {
        if (item._id === updatedItem._id) {
          tempList[i] = updatedItem
        }
      })
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: tempList
        }
      }
    case DELETE_ACCOUNT:
      let deletedItem = payload.data;
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.filter(item => (item._id !== deletedItem._id)),
          isLoading: false,
          hasError: payload.hasError
        }
      }
    default: return state;
  }
}

export default account;
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  useTheme,
  withStyles,
  Typography,
  Link,
} from "@material-ui/core";
import {
  TextField as CustomTextField,
  Button,
  PasswordField,
} from "../../../commons/mui";
import useStyles from "../../../styles/jss/style";
import { DIALOGS } from "../../../commons/CONSTANTS";
import {
  changePassword,
  forgot,
  signIn,
  verify,
} from "../../../redux/actions/auth";
import { useHistory } from "react-router-dom";
import {
  CHANGE_PASSWORD,
  SET_CURRENT_USER,
  VERIFY,
} from "../../../redux/action_types";
import OTPInput from "react-otp-input";
import { setCompanyHeader } from "../../../utils/setAuthToken";
import { listRoles } from "../../../redux/actions/companyRole";
import { decrypt, encrypt } from "n-krypta";
import { toast } from "react-toastify";
import { BRM, ASSOCIATE, AUDITOR, PDSLADMIN, CUSTOMER, TECHNICIAN } from "../../Projects/AllProjects/CONSTANTS";

const TextField = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}))(CustomTextField);

const Index = () => {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const localCompany = useSelector((state) => state.localCompany);
  const roles = useSelector((state) => state.companyRole.list.data);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => { },
    },
    btn2: {
      text: "Yes",
      onClick: () => { },
    },
  });
  const [loginForm, setLoginForm] = useState(true);
  const [verifyForm, setVerifyForm] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [resetForm, setResetForm] = useState(false);

  useEffect(() => {
    dispatch(listRoles())
  }, []);
  useEffect(() => {
    // console.log(auth)
    if (localCompany?.companyId?.data || auth.data?.companyId) {
      history.push('/company-login/' + auth.data?.companyId || decrypt(localCompany?.companyId?.data, 'secret'))
    } else {
      localStorage.removeItem("companyId");
      setCompanyHeader("");
    }

  }, [localCompany, auth]);

  useEffect(() => {
    // console.log(auth)
    if (localCompany.companyId?.data || auth.data?.companyId) {
      history.push('/company-login/' + auth.data?.companyId || decrypt(localCompany.companyId?.data, 'secret'))
    } else {
      localStorage.removeItem("companyId");
      setCompanyHeader("");
      if (auth?.data?.accessToken) {
        if (auth.data.isTempPassword === true) {
          setLoginForm(false);
          setResetForm(true);
        } else {
          redirect();
        }
      } else if (auth.type == SET_CURRENT_USER) {
        if (auth.hasError) {
          if (auth.data.response.status === 406) {
            setVerifyForm(true);
            setLoginForm(false);
          }
        }
      } else if (auth.type == VERIFY) {
        setVerifyForm(false);
        setLoginForm(true);
        setOtp("");
      } else if (auth.type == CHANGE_PASSWORD) {
        setVerifyForm(false);
        setResetForm(false);
        toast.success("Password change successfull");
        setLoginForm(true);
        redirect();
      }
    }
  }, [auth, roles]);
  const redirect = () => {
    // console.log(localCompany)
    let temp =
      roles.find(
        (item) => item._id === (auth?.data?.role?._id || auth?.data?.role)
      ) || null;
    // console.log(temp, auth, roles)
    switch (temp?.name) {
      case BRM:
        history.push("/company-list");
        break;
      case PDSLADMIN:
        history.push("/company");
        break;
      default:
        break;
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (loginForm) {
      let body = {
        emailId: e.target[0].value,
        password: e.target[1].value,
      };
      setEmail(body.emailId);
      dispatch(signIn(body));
    } else if (verifyForm) {
      let body = {
        emailId: email,
        otp: otp,
        isVerified: true,
      };
      dispatch(verify(body));
    } else if (resetForm) {
      if (e.target[0].value == e.target[1].value) {
        let body = {
          emailId: email,
          password: e.target[0].value,
        };
        dispatch(changePassword(body));
      } else {
        toast.error("Password and confirm not matched!");
      }
    }
  };
  const gotForgot = () => {
    history.push("/forgot");
  };
  const handleOtp = (newVal) => {
    setOtp(newVal);
  };
  const resend = () => {
    let body = {
      emailId: email,
    };
    setEmail(body.emailId);
    dispatch(forgot(body));
  };

  return (
    <Grid container className={`${classes.paddingX} ${classes.stretchHeight}`}>
      {/* <Grid item container className={`${classes.height5}`}>
        <Typography variant='h6' align='left' gutterBottom>Login</Typography>
      </Grid> */}
      <Grid item container direction="column">
        <Grid
          item
          container
          direction="row"
          className={`${classes.fullWidth} ${classes.stretchHeight}`}
        >
          <Grid
            item
            container
            xs={12}
            direction="row"
            className={`${classes.stretchHeight}`}
          >
            {loginForm ? (
              <form
                onSubmit={(e) => onSubmit(e)}
                className={`${classes.fullWidth} ${classes.stretchHeight} ${classes.verticalAlign}`}
                style={{ display: "flex" }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignContent="center"
                  style={{
                    background: theme.palette.accent.main,
                    maxWidth: "400px",
                  }}
                  className={`${classes.paddingX} ${classes.paddingY}`}
                >
                  <Grid
                    key={"g-1"}
                    item
                    className={`${classes.marginX} ${classes.paddingY}`}
                  >
                    <Typography variant="h5" align="center">
                      Sign In
                    </Typography>
                  </Grid>
                  <Grid
                    key={"g-2"}
                    item
                    className={`${classes.marginX} ${classes.paddingY}`}
                  >
                    <TextField
                      name="emailId"
                      type="email"
                      fullWidth
                      variant="standard"
                      label="Enter Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid
                    key={"g-3"}
                    item
                    className={`${classes.marginX} ${classes.paddingBottom}`}
                  >
                    {/* <TextField name='password' fullWidth variant='standard' label='Enter Password' type='password' /> */}
                    <PasswordField
                      label="Password"
                      fullWidth
                      variant="standard"
                    />
                    <Link
                      style={{ float: "right", cursor: "pointer" }}
                      onClick={gotForgot}
                    >
                      Forgot Password
                    </Link>
                  </Grid>
                  <Grid
                    key={"g-4"}
                    item
                    className={`${classes.marginX} ${classes.paddingBottom}`}
                  >
                    <Button
                      type="submit"
                      marginLeft="0px"
                      marginRight="0px"
                      width="300px"
                      variant="contained"
                      color="primary"
                      text="Sign In"
                    />
                  </Grid>
                </Grid>
              </form>
            ) : verifyForm ? (
              <form
                onSubmit={(e) => onSubmit(e)}
                className={`${classes.fullWidth} ${classes.stretchHeight} ${classes.verticalAlign}`}
                style={{ display: "flex" }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignContent="center"
                  style={{
                    background: theme.palette.accent.main,
                    width: "400px",
                  }}
                  className={`${classes.paddingX} ${classes.paddingY}`}
                >
                  <Grid
                    key={"g-1"}
                    item
                    className={`${classes.marginX} ${classes.paddingY}`}
                  >
                    <Typography variant="h5" align="center">
                      Verify
                    </Typography>
                  </Grid>
                  <Grid
                    key={"g-2"}
                    item
                    className={`${classes.marginX} ${classes.paddingY}`}
                  >
                    <OTPInput
                      value={otp}
                      onChange={handleOtp}
                      numInputs={6}
                      inputStyle={{
                        width: "3rem",
                        height: "3rem",
                        margin: 0,
                        fontSize: "2rem",
                        borderRadius: 4,
                        border: "1px solid rgba(0,0,0,.3)",
                      }}
                      containerStyle={{ justifyContent: "center" }}
                      shouldAutoFocus={true}
                      // inputType='number'
                      renderSeparator={
                        <span style={{ margin: "0 5px 0 5px" }}>-</span>
                      }
                      renderInput={(props) => <input {...props} />}
                    />
                    <Link
                      style={{ float: "right", cursor: "pointer" }}
                      onClick={resend}
                    >
                      Resend
                    </Link>
                  </Grid>
                  <Grid
                    key={"g-3"}
                    item
                    className={`${classes.marginX} ${classes.paddingBottom}`}
                  >
                    <Button
                      type="submit"
                      marginLeft="0px"
                      marginRight="0px"
                      width="100%"
                      variant="contained"
                      color="primary"
                      text="Verify"
                    />
                  </Grid>
                </Grid>
              </form>
            ) : resetForm ? (
              <form
                onSubmit={(e) => onSubmit(e)}
                className={`${classes.fullWidth} ${classes.stretchHeight} ${classes.verticalAlign}`}
                style={{ display: "flex" }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignContent="center"
                  style={{
                    background: theme.palette.accent.main,
                    width: "400px",
                  }}
                  className={`${classes.paddingX} ${classes.paddingY}`}
                >
                  <Grid
                    key={"g-1"}
                    item
                    className={`${classes.marginX} ${classes.paddingY}`}
                  >
                    <Typography variant="h5" align="center">
                      Reset Password
                    </Typography>
                  </Grid>
                  <Grid
                    key={"g-2"}
                    item
                    className={`${classes.marginX} ${classes.paddingY}`}
                  >
                    {/* <TextField name='password' type='password' fullWidth variant='standard' label='Enter New Password' /> */}
                    <PasswordField
                      label="Enter New Password"
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    key={"g-4"}
                    item
                    className={`${classes.marginX} ${classes.paddingY}`}
                  >
                    {/* <TextField name='confirmPassword' type='password' fullWidth variant='standard' label='Enter New Confirm Password' /> */}
                    <PasswordField
                      label="Enter New Confirm Password"
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    key={"g-3"}
                    item
                    className={`${classes.marginX} ${classes.paddingBottom}`}
                  >
                    <Button
                      type="submit"
                      marginLeft="0px"
                      marginRight="0px"
                      width="300px"
                      variant="contained"
                      color="primary"
                      text="Reset"
                    />
                  </Grid>
                </Grid>
              </form>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
    </Grid>
  );
};

export default Index;

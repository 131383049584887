import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  IconButton,
  Table,
  TextField,
} from "../../../commons/mui";
import { useDispatch, useSelector } from "react-redux";
import {
  addTemplate,
  getTemplate,
  listTemplates,
  updateTemplate,
} from "../../../redux/actions/assessmentTemplate";
import {
  getAssessmentQuestion,
  listAssessmentQuestions,
} from "../../../redux/actions/assessmentQuestion";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { QUESTION_TYPE, TABLE_HEADER, TEMPLATE_TYPES } from "./CONSTANTS";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
  input: {
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
    },
  },
}));

export default function Index() {
  const [field, setField] = useState({
    name: "",
    description: "",
    environment: "",
    assessmentQuestions: [],
    type: ''
  });
  const classes = useStyles();
  const [questionList, setQuestionList] = useState([]);
  const [errors, seterrors] = useState({});
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const template = useSelector((state) => state.assessmentTemplate);
  const { templateId } = useParams();

  useEffect(() => {
    setField({ ...field, assessmentQuestions: processQuestions(questionList) });
  }, [questionList]);
  // useEffect(() => {
  //   setField({ ...data });
  // }, [data])
  useEffect(() => {
    if (templateId) {
      dispatch(getTemplate(templateId));
    }
  }, []);
  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.name) {
      error = true;
      temp.name = "Name is required";
    }
    if (!field.description) {
      error = true;
      temp.description = "Description is required";
    }
    if (!field.assessmentQuestions) {
      error = true;
      temp.assessmentQuestions = "Assessment questions is required";
    }
    if (!field.environment) {
      error = true;
      temp.environment = "Environment is required";
    }
    if (!field.type) {
      error = true;
      temp.type = "Type is required";
    }
    seterrors(temp);
    // console.log(field)
    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      let data = { ...field };
      data.assessmentQuestions = data.assessmentQuestions.map((a) => {
        delete a.referenceView;
        delete a.questionView;
        delete a.typeView;
        return a;
      });
      if (templateId) {
        dispatch(updateTemplate(data, templateId));
      } else {
        dispatch(addTemplate(data));
      }
      history.push("/assessment-template");
    }
  };

  useEffect(() => {
    if (templateId && template.detail.data._id) {
      setQuestionList(template.detail.data.assessmentQuestions);
      setField({
        name: template.detail.data.name,
        description: template.detail.data.description,
        environment: template.detail.data.environment,
        type: template.detail.data.type,
        assessmentQuestions: processQuestions(
          template.detail.data.assessmentQuestions
        ),
      });
    }
  }, [template]);
  const processQuestions = (questions) => {
    return questions.map((a, i) => {
      a.errors = a.errors || {};
      a.referenceView = (
        <TextField
          variant="filled"
          placeholder="Reference"
          value={a.reference}
          isError={a.errors?.reference}
          onChange={(e) => {
            setQuestionsFields("reference", e.target.value, i);
          }}
          fullWidth
        />
      );
      a.questionView = (
        <TextField
          variant="filled"
          placeholder="Questions"
          value={a.question}
          isError={a.errors?.question}
          onChange={(e) => {
            setQuestionsFields("question", e.target.value, i);
          }}
          multiline
          minRow={3}
          fullWidth
        />
      );
      a.typeView = (
        <TextField
          label="Type"
          variant="filled"
          color="secondary"
          value={a.type}
          select={true}
          options={QUESTION_TYPE}
          fullWidth
          isError={a.errors?.type}
          style={{ textAlign: "left" }}
          onChange={(e) => {
            setQuestionsFields("type", e.target.value, i);
          }}
        />
      );
      return a;
    });
  };
  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm == type) {
        setField({ ...field, [itm]: val });
      }
    });
  };
  const setQuestionsFields = (type, val, i) => {
    let questions = questionList;
    if (questions[i]) {
      questions[i][type] = val;
      setQuestionList(questions);
      setField({ ...field, assessmentQuestions: processQuestions(questions) });
    }
  };
  return (
    <Grid>
      <Grid>
        <Grid>
          <h2>
            <IconButton
              color="primary"
              onClick={() => {
                history.push("/assessment-template");
              }}
              icon=<ArrowBack />
            ></IconButton>
            {!templateId ? "Add" : "Edit"} Template
          </h2>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Name"
              value={field.name}
              isError={errors.name}
              onChange={(e) => {
                setFields("name", e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Environment"
              value={field.environment}
              isError={errors.environment}
              onChange={(e) => {
                setFields("environment", e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Description"
              value={field.description}
              isError={errors.description}
              onChange={(e) => {
                setFields("description", e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Type"
              value={TEMPLATE_TYPES.find((a) => a.value === field.type)}
              isError={errors.type}
              onChange={(e) => {
                setFields("type", e.target.value);
              }}
              select={true}
              options={TEMPLATE_TYPES}
              fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <Table
              header={TABLE_HEADER}
              data={field.assessmentQuestions}
              onDelete={(i, e, row) => {
                let questions = field.assessmentQuestions;
                // questions.splice(i, 1);
                delete questions[i];
                setQuestionList(questions);
              }}
              onEdit={(i, row) => {
                // console.log(row)
              }}
              editBtn={false}
              addRow={true}
              onAddRow={(e) => {
                let questions = field.assessmentQuestions;
                questions.push({ errors: {} });
                setQuestionList(questions);
              }}
            />
            {/* <AutoComplete
              variant="filled"
              label="Assesment Questions"
              defaultValue={field.assessmentQuestions}
              // value={field.assessmentQuestions.map(a=>a._id)}
              options={questionList}
              isError={errors.assessmentQuestions}
              onChange={(e, newValue) => {
                // console.log(newValue);
                setFields("assessmentQuestions", newValue);
              }}
              multiple
              fullWidth
              required={true}
              customOptionLabel={(option) => option.question}
              className={classes.root}
            /> */}
          </Grid>
        </Grid>
        <Grid style={{ textAlign: "center" }}>
          <Button
            style={{ marginTop: "1.7rem" }}
            width="150px"
            text={templateId ? "Update" : "Submit"}
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

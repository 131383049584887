import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    direction: 'column',
    height: '100%',
    minHeight: '100vh',
    justifyContent: 'space-around',
    spacing: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minWidth: 'calc(100% - 64px)',
    maxWidth: 'calc(100% - 240px)'
  },
  contentDrawerOpen: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: 'calc(100% - 240px)'
  },
  contentGrid: {
    height: `calc(100% - 64px)`,
    direction: 'column',
    spacing: 0,
  }
}));

export default useStyles;
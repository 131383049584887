import React, { useDebugValue, useEffect, useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import { ActionTable, Button, SingleHeaderTable } from "../../../commons/mui";
import useStyles from "../../../styles/jss/style";
import { TABLE_HEADER } from "./CONSTANTS";
import { useDispatch, useSelector } from "react-redux";
import {
  clearData,
  listProjectAssessment,
  updateProjectAssessment,
} from "../../../redux/actions/projectAssessment";
import { clearProjectData, getProject } from "../../../redux/actions/project";
import { GET_PROJECT } from "../../../redux/action_types";

const Index = ({ data = null }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setpage] = useState(1);
  // const [data, setdata] = useState([])
  const [tableData, settableData] = useState([]);
  const project = useSelector((state) => state.project);
  const [projectData, setProjectData] = useState({});
  const projectAssessments = useSelector((state) => state.projectAssessments);
  useEffect(() => {
    // console.log(data);
  }, [data]);

  useEffect(() => {
    dispatch(
      listProjectAssessment({ project: data?._id, skip: (page - 1) * 10 })
    );
  }, []);

  useEffect(() => {
    if (data?._id) {
      dispatch(getProject(data?._id));
    }
  }, []);

  useEffect(() => {
    if (project.detail?.data?._id) {
      dispatch(clearProjectData(GET_PROJECT));
      setProjectData(project.detail?.data);
    }
  }, [project]);

  useEffect(() => {
    if (projectAssessments.list?.data) {
      settableData(
        projectAssessments.list?.data.map((a, i) => {
          a.question = a.assessmentQuestion.question;

          a.riskAcceptableView =
            a.assessmentAnswer == "Yes" ? (
              <div>
                {a.riskAcceptable ? (
                  <>
                    <Typography style={{ fontWeight: "bold" }}>
                      {a.riskAcceptable}
                    </Typography>
                    <br />
                  </>
                ) : null}

                <Button
                  onClick={(e) => {
                    let upData = { riskAcceptable: "Yes" };
                    dispatch(updateProjectAssessment(upData, a._id));
                    setTimeout(() => {
                      listProjectAssessment({
                        project: data?._id,
                        skip: (page - 1) * 10,
                      });
                    }, 100);
                  }}
                  text="Yes"
                  variant="contained"
                  disabled={a.riskAcceptable == "Yes" || projectData.auditDone}
                  color="primary"
                />
                <Button
                  onClick={(e) => {
                    let upData = { riskAcceptable: "No" };
                    dispatch(updateProjectAssessment(upData, a._id));
                    setTimeout(() => {
                      listProjectAssessment({
                        project: data?._id,
                        skip: (page - 1) * 10,
                      });
                    }, 100);
                  }}
                  text="No"
                  variant="contained"
                  color="primary"
                  disabled={a.riskAcceptable == "No" || projectData.auditDone}
                />
              </div>
            ) : (
              <Typography style={{ fontWeight: "bold" }}>
                No Risk Identified
              </Typography>
            );
          return a;
        })
      );
    }
    if (projectAssessments?.update?.data) {
      dispatch(clearData());
      dispatch(
        listProjectAssessment({ project: data?._id, skip: (page - 1) * 10 })
      );
    }
  }, [projectAssessments]);

  return (
    <Grid container>
      <Grid container className={`${classes.paddingBottom}`}>
        <Grid container spacing={2}>
          <Grid item container sm={12} md={6}>
            <SingleHeaderTable
              header="Project Details"
              noOfCols={1}
              rows={[
                [{ label: "Name", value: data?.name || "" }],
                [{ label: "Project No", value: data?.projectNo || "" }],
                [{ label: "Email", value: data?.emailId || "" }],
                [{ label: "SAP/Customer Ref", value: data?.sapNo || "" }],
                [{ label: "Part Name", value: data?.partName || "" }],
                [{ label: "Part No", value: data?.partNumber || "" }],
                [
                  {
                    label: "Customer Name",
                    value:
                      data?.customer?.firstName +
                        " " +
                        data?.customer?.lastName || "",
                  },
                ],
                [
                  {
                    label: "Customer Contact",
                    value: data?.customerContact || "",
                  },
                ],
                [
                  {
                    label: "Start Date",
                    value: data?.startDate?.split("T")[0],
                  },
                ],
                [
                  {
                    label: "Exit Date",
                    value: data?.expectedEndDate?.split("T")[0],
                  },
                ],
                // [{ label: "Assessment Template", value: data?.assessmentTemplate?.name }],
              ]}
            />
          </Grid>
          <Grid item container sm={12} md={6}>
            <SingleHeaderTable
              header="Work Details"
              noOfCols={1}
              rows={[
                [
                  {
                    label: "Assessment",
                    value: data?.assessmentTemplate?.name || "",
                  },
                ],
                [
                  {
                    label: "Assessment To",
                    value: data?.assessmentAssignTo?.firstName
                      ? data?.assessmentAssignTo?.firstName +
                        " " +
                        data?.assessmentAssignTo?.lastName
                      : "",
                  },
                ],
                [
                  {
                    label: "Assessment Date",
                    value: data?.assessmentAssignOn?.split("T")[0],
                  },
                ],
                [
                  {
                    label: "Audit",
                    value: data?.auditBy?.firstName
                      ? data?.auditBy?.firstName + " " + data?.auditBy?.lastName
                      : "",
                  },
                ],
                // [
                //   {
                //     label: "Audit To",
                //     value: ''
                //   },
                // ],
                [{ label: "Audit Date", value: data?.auditOn?.split("T")[0] }],
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container sm={12} className={`${classes.paddingY}`}>
        <ActionTable
          actions={false}
          header={TABLE_HEADER}
          data={tableData}
          hazardColumn={false}
          controlMeasureColumn={false}
          onControlMeasure={(i) => {}}
          onDeleteControlMeasure={(i) => {}}
        />
      </Grid>
      <Grid container className={`${classes.paddingBottom}`}>
        <SingleHeaderTable
          header="Safety Equipments Available"
          noOfCols={1}
          rows={[
            [
              { label: "", value: data?.safetyEquipments.join() || "" },
              {
                label: "Exit Date",
                value: data?.expectedEndDate?.split("T")[0],
              },
            ],
            // [{ label: "Assessment Template", value: data?.assessmentTemplate?.name }],
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Index;

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Pagination, Table, Tooltip } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { clearProjectData, listProjects, updateProject, verifyProjectSign } from "../../../redux/actions/project";
import moment from 'moment';
import { ConfirmationTemplate } from "../../Projects";
import { SIGN_PROJECT, UPDATE_PROJECT } from "../../../redux/action_types";
import { EVERSIGNPROJECTCREATION } from "../../Projects/AllProjects/CONSTANTS";
import Iframe from "react-iframe";
import { toast } from "react-toastify";



export default function Index() {
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const project = useSelector((state) => state.project);
  const [selectedEsignProject, setSelectedEsignProject] = useState({});
  const [openTemplate, setOpenTemplate] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [eversignDetail, setEversignDetail] = useState({});
  const [signLoader, setSignLoader] = useState(false);

  // useEffect(() => {
  //   if (auth.data._id) {
  //     dispatch(listProjects({ customer: auth.data._id }));
  //     // setInterval(() => { dispatch(listProjects({ customer: auth.data._id })); }, ( 10 * 1000))
  //   }
  // }, [auth]);
  useEffect(() => {
    if (auth.data?._id) {
      dispatch(listProjects({ customer: auth.data?._id, skip: 10 * (page - 1) }));
    }
  }, [page]);

  useEffect(() => {
    if (project.list.data && project.list.data.length) {
      let temp = project.list.data.map((a) => {
        if (a._id === selectedEsignProject._id) {
          let everSign = a.status === 'Awaiting Customer to E-Sign' ? (a.eversignList && a.eversignList.length ? a.eversignList.find(a => a.type === EVERSIGNPROJECTCREATION) : {}) : {};
          // console.log(everSign, a.eversignList)
          let signer = everSign.meta ? everSign.meta['signer-0'] : {};
          if (signer.userId !== auth.data?._id) {
            signer = everSign.meta ? everSign.meta['signer-1'] : {};
          }
          signer.document_hash = everSign.meta?.document_hash;
          // console.log(signer)
          if (signer.status === 'signed' && openTemplate) {
            handleEverSignUpdate();
          } else if (signer.status && openTemplate) {
            toast.error('Signature not done yet!')
          }
        }
        a.projectNoView = (
          <Grid>
            <Link onClick={() => {
              history.push(`/customer-projects/${a._id}`);
            }} style={{ cursor: 'pointer' }}>{a.projectNo}</Link>
          </Grid>
        );
        a.dateCreated = moment().format('YYYY-MM-DD hh:mm A');
        a.actionView = (
          a.status === 'Awaiting Customer to E-Sign' ?
            (
              <Tooltip
                title={`E-Sign`}
                placement='bottom'
                Component={() => <Button
                  onClick={(e) => {
                    setSelectedEsignProject(a);
                    let everSign = a.status === 'Awaiting Customer to E-Sign' ? (a.eversignList && a.eversignList.length ? a.eversignList.find(a => a.type === EVERSIGNPROJECTCREATION) : {}) : {};
                    // console.log(everSign)
                    let signer = everSign.meta ? everSign.meta['signer-0'] : {};
                    if (signer.userId !== auth.data._id) {
                      signer = everSign.meta ? everSign.meta['signer-1'] : {};
                    }
                    signer.document_hash = everSign.meta?.document_hash;
                    // console.log(signer)
                    setEversignDetail(signer)
                    setOpenTemplate(true)
                  }}
                  text="E-Sign"
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: ".5rem" }
                  }
                />}
              />) :
            a.status === 'Awaiting Work plan' ?
              <Tooltip
                title={`Work Plans`}
                placement='bottom'
                Component={() => <Button
                  onClick={(e) => {
                    history.push('/workplan-list/' + a._id)
                  }}
                  text="Work Plans"
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: ".5rem" }
                  }
                />}
              />
              : null
        );
        return a;
      });
      setdata(temp);
    }
    if (project.update && project.update.data && project.update.data._id) {
      dispatch(clearProjectData(UPDATE_PROJECT))
      dispatch(listProjects({ customer: auth.data._id, skip: 10 * (page - 1) }));
    }
    if (project.sign && project.sign.data && project.sign.data._id) {
      dispatch(clearProjectData(SIGN_PROJECT))
      setSignLoader(false)
      dispatch(listProjects({ skip: 10 * (page - 1) }));
    }
  }, [project]);
  const handleEverSignUpdate = () => {
    let upData = { status: 'Awaiting Risk Assessment' };
    dispatch(updateProject(upData, selectedEsignProject._id))
    setOpenTemplate(false);
    setIframeLoaded(false)
  }
  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>Project List</h2>
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={data}
          startBtn={false}
          actions={false}
          onStart={(e, i, row) => {

          }}
        />
        <Grid
          item
          container
          style={{ justifyContent: "center" }}
        >
          <div className="ui-components">
            <Pagination
              count={project.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
      </Grid>
      <div>
        <Dialog
          open={openTemplate}
          // onClose={() => setOpenTemplate(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen
        >
          <DialogTitle id="alert-dialog-title">{selectedEsignProject.status === "Awaiting Review E-Sign BRM" ? "Assessment Review" : "Project Configure"}</DialogTitle>
          <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
            {!iframeLoaded ?
              <Grid style={{
                position: "absolute",
                width: "99vw",
                height: "92vh",
                margin: '0 auto',
                background: "#ccc",
                zIndex: 9,
                overflow: "hidden",
              }}>
                <CircularProgress disableShrink style={{ margin: '25% 50%' }} />
              </Grid>
              : null}
            <Grid style={{ padding: 20 }}>
              <Grid style={{ width: '50%', float: 'left' }}>
                <DialogContentText id="alert-dialog-description">PIN: <strong>{eversignDetail.pin}</strong>
                  {/* <label style={{ color: 'red', float: 'right' }}>{signError}</label> */}
                </DialogContentText>
              </Grid>
              <Grid style={{ width: '50%', float: 'left' }}>
                <Button
                  onClick={(e) => {
                    setSignLoader(true)
                    let upData = { meta: { related_document_hash: eversignDetail.document_hash } };
                    dispatch(verifyProjectSign(upData))
                  }}
                  text="Verify E-Sign"
                  variant="contained"
                  color="primary"
                  customClass="float-right green-button"
                  startIcon={signLoader ? <CircularProgress size={30} style={{ color: '#fff' }} /> : null}
                />
              </Grid>
              <Iframe url={eversignDetail.embedded_signing_url}
                // width="650px"
                // height="650px"
                styles={{ width: '95vw', height: '76vh', margin: '0 auto' }}
                id=""
                frameBorder={0}
                className=""
                onLoad={(e) => {
                  console.log(e)
                  setIframeLoaded(true)
                }}
                display="block"
                position="relative" />
            </Grid>
            {/* <DialogContentText id="alert-dialog-description">
              <ConfirmationTemplate data={selectedEsignProject}></ConfirmationTemplate>
            </DialogContentText> */}
          </DialogContent>
          {/* <DialogActions>
            <Button
              onClick={(e) => {
                setSignError("")
                let upData = { meta: { related_document_hash: eversignDetail.document_hash } };
                dispatch(verifyProjectSign(upData))
              }}
              text="Verify E-Sign"
              variant="contained"
              color="primary"
            />
          </DialogActions> */}
        </Dialog>
      </div>
    </div >
  );
}

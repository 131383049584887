import { 
  LIST_BAY_TYPE,
  GET_BAY_TYPE,
  ADD_BAY_TYPE,
  UPDATE_BAY_TYPE,
  DELETE_BAY_TYPE  
} from '../action_types';

const initialState = {
  list: {
      pagination: {},
      totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false
  }
}

const bayType = (state=initialState, action)  => {
  const { type, payload } = action;
  switch(type) {
    case LIST_BAY_TYPE: 
      return {
        ...state,
        list: {
          ...state.list,
          ...payload
        }
      };
    case GET_BAY_TYPE:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload
        }
      };
    case ADD_BAY_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          isLoading: false,
          hasError: payload.hasError,
        }
      }
    case UPDATE_BAY_TYPE:
      let updatedItem = payload.data;
      let tempList = [...state.list.data]
      tempList.forEach((item, i) => {
        if(item._id === updatedItem._id) {
          tempList[i] = updatedItem
        }
      })
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: tempList
        }
      }
    case DELETE_BAY_TYPE:
      let deletedItem = payload.data;
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.filter(item => (item._id !== deletedItem._id)),
          isLoading:false,
          hasError: payload.hasError
        }
      }
    default: return state;
  }
}

export default bayType;
import {
  LIST_ASSESSMENT,
  GET_ASSESSMENT,
  ADD_ASSESSMENT,
  UPDATE_ASSESSMENT,
  DELETE_ASSESSMENT,
} from "../action_types";

const initialState = {
  list: {
      pagination: {},
      totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false,
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false,
  },
};

const assessment = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_ASSESSMENT:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
        },
      };
    case GET_ASSESSMENT:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
        },
      };
    case ADD_ASSESSMENT:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          isLoading: false,
          hasError: payload.hasError,
        },
      };
    case UPDATE_ASSESSMENT:
      let updatedItem = payload.data;
      let tempList = [...state.list.data];
      tempList.forEach((item, i) => {
        if (item._id === updatedItem._id) {
          tempList[i] = updatedItem;
        }
      });
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: tempList,
        },
      };
    case DELETE_ASSESSMENT:
      let deletedItem = payload.data;
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.filter((item) => item._id !== deletedItem._id),
          isLoading: false,
          hasError: payload.hasError,
        },
      };
    default:
      return state;
  }
};

export default assessment;

import { callAPI } from "../../utils/apiHelper";
import {
  LIST_TECHNICIAN_WORK_PLAN_HISTORY,
  GET_TECHNICIAN_WORK_PLAN_HISTORY,
  ADD_TECHNICIAN_WORK_PLAN_HISTORY,
  UPDATE_TECHNICIAN_WORK_PLAN_HISTORY,
  DELETE_TECHNICIAN_WORK_PLAN_HISTORY
} from "../action_types";

export const listTechnicianPlanLogs = (params) => async (dispatch) => {
  // let queryString = QS(params)
  callAPI(dispatch,`/technician_workplan_history`, 'GET',LIST_TECHNICIAN_WORK_PLAN_HISTORY,params,true);
};


export const getTechnicianPlanLog = (id) => async (dispatch) => {
  callAPI(dispatch,`/technician_workplan_history/${id}`, 'GET',GET_TECHNICIAN_WORK_PLAN_HISTORY,{},true);
};

export const addTechnicianPlanLog = (data) => async (dispatch) => {
  callAPI(dispatch,`/technician_workplan_history`, 'POST',ADD_TECHNICIAN_WORK_PLAN_HISTORY,{},false,data);
};

export const updateTechnicianPlanLog = (data, id) => async (dispatch) => {
  callAPI(dispatch,`/technician_workplan_history/${id}`, 'PUT',UPDATE_TECHNICIAN_WORK_PLAN_HISTORY,{},false,data);
};


export const deleteTechnicianPlanLog = (id) => async (dispatch) => {
  callAPI(dispatch,`/technician_workplan_history/${id}`, 'DELETE',DELETE_TECHNICIAN_WORK_PLAN_HISTORY,{},false);
};
export const clearTechnicianPlanLogData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_TECHNICIAN_WORK_PLAN_HISTORY,
    payload: {
      data: null,
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
}; 

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Pagination, Table } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { deleteUser, listUsers } from "../../../redux/actions/user";
import { Grid } from "@material-ui/core";
import { encrypt } from "n-krypta";
import { setCompanyHeader } from "../../../utils/setAuthToken";
import { ArrowBack } from "@material-ui/icons";
import { BRM } from "../../Projects/AllProjects/CONSTANTS";
import axios from "axios";
import { toast } from "react-toastify";
import { DIALOGS } from "../../../commons/CONSTANTS";
import useStyles from "../../../styles/jss/style";

export default function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const { companyId } = useParams();
  const [companyLId, setCompanyLId] = useState("");
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => {},
    },
    btn2: {
      text: "Yes",
      onClick: () => {},
    },
  });
  useEffect(() => {
    if (localStorage.getItem("comapnyId"))
      setCompanyLId(localStorage.getItem("comapnyId"));
    if (companyId) setCompanyHeader(encrypt(companyId, "secret"));
    // if(axios)
    // dispatch(listUsers());
  }, []);
  useEffect(() => {
    dispatch(listUsers({ skip: 10 * (page - 1) }));
  }, [page]);

  useEffect(() => {
    // console.log(user)
    if (user.list.data) {
      user.list.data = user.list.data.map((a) => {
        a.name = a.firstName + (a.lastName ? " " + a.lastName : "");
        a.projects = a._id;
        a.role = a.role && a.role._id ? a.role.name : "";
        return a;
      });
      // console.log(user)
      setdata(user.list.data);
    }
  }, [user]);

  const onEdit = (e, row) => {
    history.push(
      (auth.data?.role?.name === BRM ? "/brm-" : "/") +
        "company-edit-user/" +
        row._id
    );
  };

  const onDelete = (i, e, item) => {
    // dispatch(deleteUser(row._id))
    // setTimeout(() => {
    //     dispatch(listUsers())
    // }, 500);
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete user. Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => {
          if (item._id !== auth.data._id) {
            dispatch(deleteUser(item._id));
            setTimeout(() => {
              dispatch(listUsers({ skip: (page - 1) * 10 }));
            }, 500);
          } else {
            toast.error("You can't delete yourself");
          }
        },
      },
    }));
  };

  const onLogin = (row, column) => {
    // localStorage.setItem('companyId', bcrypt.hashSync(row._id, 10))
    // console.log(row, column);

    // dispatch(clearData())
    history.push(
      (auth.data?.role?.name === BRM ? "/brm-" : "/") +
        "company-project-list/" +
        row._id
    );
    // let id = bcrypt.hashSync(row._id, 10);
    // localStorage.setItem('id', id)
  };

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>
          <IconButton
            color="primary"
            onClick={() =>
              history.push(
                auth.data?.role?.name === BRM ? "/company-list" : "/company"
              )
            }
            icon=<ArrowBack />
          ></IconButton>
          User List
        </h2>
        <Grid>
          <Button
            width="200px"
            text="Projects"
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(
                (auth.data?.role?.name === BRM ? "/brm-" : "/") +
                  "company-project-list/" +
                  companyId
              )
            }
          />
          <Button
            width="200px"
            text="Roles"
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(
                (auth.data?.role?.name === BRM ? "/brm-" : "/") +
                  "company-role-list/" +
                  companyId
              )
            }
          />
          <Button
            width="200px"
            text="Add User"
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(
                (auth.data?.role?.name === BRM ? "/brm-" : "/") +
                  "company-add-user"
              )
            }
          />
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          onLogin={onLogin}
        />
        <Grid item container style={{ justifyContent: "center" }}>
          <div className="ui-components">
            <Pagination
              count={user.list.data.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
    </div>
  );
}

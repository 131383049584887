import { CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../../commons/mui';
import Iframe from 'react-iframe';

const Index = ({
    open = false,
    title = '',
    eversignDetail = {},
    signLoader = false,
    verifySign = () => { }
}) => {
    const [iframeLoaded, setIframeLoaded] = useState(false);

    return (
        <Dialog
            open={open}
            // onClose={() => setOpenTemplate(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullScreen
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent style={{ padding: 0, overflow: "hidden" }}>
                {!iframeLoaded ? (
                    <Grid
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100vh",
                            margin: '0 auto',
                            background: "#ccc",
                            zIndex: 9,
                            overflow: "hidden",
                        }}
                    >
                        <CircularProgress disableShrink style={{ margin: "25% 50%" }} />
                    </Grid>
                ) : null}
                <Grid style={{ padding: 20 }}>
                    <Grid style={{ width: '50%', float: 'left' }}>
                        <DialogContentText id="alert-dialog-description">
                            PIN: <strong>{eversignDetail.pin}</strong>{" "}
                        </DialogContentText>
                    </Grid>
                    <Grid style={{ width: '50%', float: 'left' }}>
                        <Button
                            onClick={(e) => verifySign()}
                            text="Verify E-Sign"
                            variant="contained"
                            color={'green'}
                            customClass="float-right green-button"
                            startIcon={signLoader ? <CircularProgress size={30} style={{ color: '#fff' }} /> : null}
                        />
                    </Grid>

                    <Iframe
                        url={eversignDetail.embedded_signing_url}
                        styles={{ width: '100%', height: '95vh', margin: '0 auto' }}
                        id=""
                        frameBorder={0}
                        className=""
                        onLoad={(e) => {
                            setIframeLoaded(true);
                        }}
                        display="block"
                        position="relative"
                    />
                </Grid>
            </DialogContent>

        </Dialog>
    );
}
export default Index;

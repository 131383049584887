import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  IconButton,
  TextField,
} from "../../../commons/mui";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { listTemplates } from "../../../redux/actions/assessmentTemplate";
import { listUsers } from "../../../redux/actions/user";
import { listRoles } from "../../../redux/actions/companyRole";
import { listLocations } from "../../../redux/actions/location";
import {
  addProject,
  clearProjectData,
  cloneProject,
  getProject,
  updateProject,
} from "../../../redux/actions/project";
import { BRM, CUSTOMER, REPORTING_FREQUENCY } from "../AllProjects/CONSTANTS";
import ReactDatePicker from "react-datepicker";
import { ArrowBack } from "@material-ui/icons";
import axios from "axios";
import { commodityList, workType } from "./CONSTANTS";
import {
  ADD_PROJECT,
  CLONE_PROJECT,
  GET_PROJECT,
  UPDATE_PROJECT,
} from "../../../redux/action_types";

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const users = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.companyRole);
  const assessmentTemplate = useSelector((state) => state.assessmentTemplate);
  const locations = useSelector((state) => state.location);
  const [customerList, setCustomerList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [field, setField] = useState({ workLocation: [], location: [] });
  const [errors, seterrors] = useState({});
  const [fromSuperAdmin, setFromSuperAdmin] = useState(false);
  const { projectId } = useParams();
  const companyId = axios.defaults.headers.common["Company"];
  const [contactList, setContactList] = useState([]);
  const [allContactList, setAllContactList] = useState([]);
  const [clone, setclone] = useState(false);

  useEffect(() => {
    let companyLId = localStorage.getItem("companyId");
    setTimeout(() => {
      dispatch(listTemplates({ type: ['P', 'D'], limit: 0 }));
      dispatch(listRoles({ limit: 0 }));
      dispatch(listLocations({ limit: 0 }));
      if (projectId) dispatch(getProject(projectId));
    }, 100);
    if (
      window.location.href.indexOf(
        (auth.data?.role?.name === BRM ? "brm-" : "") + "edit-project"
      ) === -1 &&
      window.location.href.indexOf(
        (auth.data?.role?.name === BRM ? "brm-" : "") + "create-project"
      ) === -1 &&
      window.location.href.indexOf(
        (auth.data?.role?.name === BRM ? "brm-" : "") + "clone-project"
      ) === -1
    )
      setFromSuperAdmin(false);
    else setFromSuperAdmin(true);
    if (window.location.href.indexOf("clone") != -1) {
      setclone(true);
    }
  }, []);
  useEffect(() => {
    if (users.list.data && users.list.data.length) {
      setCustomerList(users.list.data);
    }
  }, [users]);
  useEffect(() => {
    if (locations.list.data && locations.list.data.length) {
      // console.log(locations.list.data)
      let allCnts = [];
      locations.list.data.map(a => {
        if (a.contacts) {
          a.contacts.map((item) => {
            let obj = item;
            obj.name = obj.contactName + " " + "(" + obj.contactNumber + ")";
            obj.location = a;
            delete obj.location.contacts;
            allCnts.push(obj);
            return item;
          });
          return a;
        }
      })
      setAllContactList(allCnts)
      setLocationList(locations.list.data);
    }
  }, [locations]);
  useEffect(() => {
    // console.log(contactList)
  }, [contactList]);
  const handleWorkLocation = (workLocation = []) => {
    // console.log(locationList);
    if (workLocation.length) {
      let combine = [];
      // locationList.filter(a => workLocation.filter(aa => aa._id === a._id).length).map((itm) => {
      //   if (itm.contacts) {
      //     itm.contacts.map((item) => {
      //       let obj = item;
      //       obj.name = obj.contactName + " " + "(" + obj.contactNumber + ")";
      //       obj.location = itm;
      //       delete obj.location.contacts;
      //       combine.push(obj);
      //       return item;
      //     });
      //   }
      //   return itm;
      // });
      // console.log(combine, field.workLocation)
      setContactList(allContactList.filter(a => workLocation.filter(aa => aa._id === a.location._id).length));
    } else {
      setContactList([]);
    }
  };

  useEffect(() => {
    if (assessmentTemplate.list.data && assessmentTemplate.list.data.length) {
      setTemplateList(
        assessmentTemplate.list.data.map((a) => ({ _id: a._id, name: a.name }))
      );
      let d = assessmentTemplate.list.data.find(a => a.type === 'D');
      // console.log(assessmentTemplate.list.data, d)
      setField({ ...field, assessmentTemplate: d })
    }
  }, [assessmentTemplate]);
  useEffect(() => {
    if (roles.list.data && roles.list.data.length) {
      let customerRole = roles.list.data.find((a) => a.name == CUSTOMER) || {};
      if (customerRole._id) {
        dispatch(listUsers({ limit: 0, role: customerRole._id }));
      }
    }
  }, [roles]);
  useEffect(() => {
    // console.log(project)
    if (project.detail && project.detail.data && project.detail.data._id) {
      dispatch(clearProjectData(GET_PROJECT));
      let detail = { ...project.detail.data };
      // if (clone) {
      //   detail.name = "";
      // }
      // detail.customer = detail.customer ? detail.customer._id : '';
      // detail.location = detail.location ? detail.location._id : '';
      detail.workLocation = detail.workLocation.length
        ? detail.workLocation
          .filter((a) => a && a._id)
          .map((a) => {
            a.name = a.address || "";
            return a;
          })
        : [];
      // detail.assessmentAssignTo = detail.assessmentAssignTo ? detail.assessmentAssignTo._id : '';
      detail.assessmentTemplate = detail.assessmentTemplate
        ? detail.assessmentTemplate._id
        : "";
      delete detail.createdAt;
      if (detail.startDate) detail.startDate = new Date(detail.startDate || '');
      if (detail.expectedEndDate)
        detail.expectedEndDate = new Date(detail.expectedEndDate);
      // console.log("detail---", detail);
      if (clone) {
        handleWorkLocation(detail.workLocation);
      }
      setField({ ...field, ...detail });
      dispatch(clearProjectData(GET_PROJECT));
    }

    // console.log(project.add)
    if (project.add?.data?._id) {
      dispatch(clearProjectData(ADD_PROJECT));
      history.push("/projects");
    }
    if (project.update?.data?._id) {
      dispatch(clearProjectData(UPDATE_PROJECT));
      history.push("/projects");
    }
    if (project.clone?.data?._id) {
      dispatch(clearProjectData(CLONE_PROJECT));
      history.push("/projects");
    }
  }, [project]);
  const setFields = (type, val) => {
    setField({ ...field, [type]: val });
  };



  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.name) {
      error = true;
      temp.name = "Name is required";
    }
    if (!field.customer) {
      error = true;
      temp.customer = "Customer is required";
    }
    if (!field.emailId) {
      // error = true;
      // temp.emailId = "Email is required";
    } else if (!/$^|.+@.+..+/.test(field.emailId)) {
      error = true;
      temp.emailId = "Email is wrong";
    }
    if (field.mobileNo) {
      if (field.mobileNo.length < 10 || field.mobileNo.length > 10) {
        error = true;
        temp.mobileNo = "Length should be 10";
      }
    }
    if (!field.assessmentTemplate) {
      error = true;
      temp.assessmentTemplate = "Assessment template is required";
    }

    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate(field)) {
      let pData = field;
      pData.assessmentTemplate = pData.assessmentTemplate
        ? pData.assessmentTemplate._id || pData.assessmentTemplate
        : null;
      pData.customer = pData.customer
        ? pData.customer._id || pData.customer
        : null;
      pData.reportingFrequency = pData.reportingFrequency
        ? pData.reportingFrequency._id || pData.reportingFrequency
        : "";
      // pData.location = pData.location
      //   ? pData.location._id || pData.location
      //   : null;
      pData.workLocation = pData.workLocation
        ? pData.workLocation.map((a) => a._id)
        : [];
      pData.location = pData.location
        ? pData.location.map((a) => ({
          contactName: a.contactName,
          contactNumber: a.contactNumber,
          location: a.location,
          name: a.name
        }))
        : [];
      delete pData._id;
      delete pData.assessmentAssignOn;
      delete pData.assessmentAssignTo;
      delete pData.assessmentDone;
      delete pData.auditDone;
      delete pData.auditOn;
      delete pData.auditBy;
      delete pData.createdAt;
      delete pData.safetyEquipments;
      delete pData.projectNo;
      delete pData.createdBy;
      delete pData.updatedBy;
      delete pData.updatedAt;
      delete pData.status;
      delete pData.eversignList;
      // console.log(pData);
      if (projectId && clone) {
        if (clone) {
          pData._id = projectId;
          dispatch(cloneProject(pData))
        } else
          dispatch(addProject(pData));
      } else if (projectId && !clone) {
        dispatch(updateProject(pData, projectId));
      } else {
        pData.status = "Created";
        dispatch(addProject(pData));
      }
    }
  };

  // useEffect(() => {
  //   console.log(field);
  // }, [field]);

  return (
    <>
      <Grid style={{ display: "flex" }}>
        <Grid style={{ width: "100%", height: "80vh" }}>
          <Grid>
            <h2>
              {fromSuperAdmin ? (
                <IconButton
                  color="primary"
                  onClick={() =>
                    history.push("/company-project-list/" + companyId)
                  }
                  icon=<ArrowBack />
                ></IconButton>
              ) : (
                <IconButton
                  color="primary"
                  onClick={() => {
                    history.push("/projects");
                  }}
                  icon=<ArrowBack />
                ></IconButton>
              )}
              {clone ? "Clone" : "Create"} Project
            </h2>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="filled"
                label="Project Name"
                value={field.name}
                name="name"
                onChange={(e) => {
                  setFields(e.target.name, e.target.value);
                }}
                disabled={clone}
                isError={errors.name}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <AutoComplete
                variant="filled"
                label="Customer Name"
                color="secondary"
                value={{
                  ...field.customer,
                  name: field.customer
                    ? field.customer.firstName
                      ? field.customer.firstName +
                      (field.customer.lastName
                        ? " " + field.customer.lastName
                        : "")
                      : field.customer.emailId
                    : "",
                }}
                name="customer"
                // select={true}
                options={(customerList || []).map((a) => {
                  a.name = a.firstName
                    ? a.firstName + (a.lastName ? " " + a.lastName : "")
                    : a.emailId;
                  return a;
                })}
                helperText=""
                onChange={(e, newValue) => {
                  setFields("customer", newValue);
                }}
                isError={errors.customer}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="filled"
                label="SAP/Ref"
                color="secondary"
                value={field.sapNo}
                name="sapNo"
                helperText=""
                onChange={(e) => {
                  setFields(e.target.name, e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="filled"
                label="Rev"
                color="secondary"
                value={field.rev}
                name="rev"
                helperText=""
                onChange={(e) => {
                  setFields(e.target.name, e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="filled"
                label="PO"
                color="secondary"
                value={field.poNo}
                name="poNo"
                helperText=""
                onChange={(e) => {
                  setFields(e.target.name, e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="filled"
                label="Client Contact"
                color="secondary"
                value={field.customerContact}
                name="customerContact"
                helperText=""
                onChange={(e) => {
                  setFields(e.target.name, e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="filled"
                label="Email"
                type="email"
                value={field.emailId}
                name="emailId"
                isError={errors.emailId}
                onChange={(e) => {
                  setFields(e.target.name, e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="filled"
                label="Telephone"
                value={field.phoneNo}
                name="phoneNo"
                onChange={(e) => {
                  setFields(e.target.name, e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="filled"
                label="Mobile No"
                value={field.mobileNo}
                name="mobileNo"
                isError={errors.mobileNo}
                onChange={(e) => {
                  setFields(e.target.name, e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <AutoComplete
                label="Location of Work"
                variant="filled"
                value={(locationList || []).map((a) => {
                  a.name = a.address;
                  return a;
                }).filter(a => (field.workLocation || []).filter(aa => aa._id === a._id).length)}
                name="workLocation"
                multiple
                // select={true}
                options={(locationList || []).map((a) => {
                  a.name = a.address;
                  return a;
                })}
                onChange={(e, newValue) => {
                  // console.log(newValue);
                  setFields("workLocation", newValue);
                  handleWorkLocation(newValue);
                  // getLocation(newValue._id)
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <AutoComplete
                label="Location Contact"
                color="secondary"
                variant="filled"
                value={contactList.filter(a => (field.location || []).filter(aa => aa.contactName === a.contactName && aa.contactNumber === a.contactNumber).length)}
                multiple
                name="location"
                // select={true}
                options={contactList}
                // helperText=""
                onChange={(e, newValue) => {
                  setFields("location", newValue);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="Problem Definition/ Description"
                variant="filled"
                value={field.description}
                name="description"
                onChange={(e) => {
                  setFields(e.target.name, e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <ReactDatePicker
                selected={field.startDate}
                onChange={(date) => {
                  setFields("startDate", date);
                }}
                value={field.startDate}
                popperPlacement="top-start"
                closeOnScroll={true}
                dateFormat="yyyy-MM-dd"
                style={{ width: "100%" }}
                customInput={
                  <TextField variant="filled" label="Start Date" fullWidth />
                }
              />
              {/* <DatePicker
                selected={field.preferredStartDate}
                name="preferredStartDate"
                onChange={(date) => {
                  console.log(date);
                  setFields("preferredStartDate", date);
                }}
                maxDate={new Date()}
                popperPlacement="top-end"
                closeOnScroll={true}
                dateFormat="MMMM d, yyyy"
                customInput={
                  <CustomDateInput customClass={classes.customDate} />
                }
              /> */}
            </Grid>
            <Grid item sm={4} xs={12}>
              <ReactDatePicker
                selected={field.expectedEndDate}
                onChange={(date) => {
                  setFields("expectedEndDate", date);
                }}
                value={field.expectedEndDate}
                popperPlacement="top-start"
                closeOnScroll={true}
                dateFormat="yyyy-MM-dd"
                style={{ width: "100%" }}
                customInput={
                  <TextField variant="filled" label="Exit Date" fullWidth />
                }
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="filled"
                label="Exit Criteria"
                value={field.criteria}
                name="criteria"
                onChange={(e) => {
                  setFields(e.target.name, e.target.value);
                }}
                fullWidth
                multiline={true}
                minRows={3}
                maxRows={5}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <AutoComplete
                variant="filled"
                label="Reporting Frequency"
                color="secondary"
                value={{
                  ...REPORTING_FREQUENCY.find(
                    (a) =>
                      a._id ===
                      (field.reportingFrequency?._id ||
                        field.reportingFrequency)
                  ),
                }}
                name="reportingFrequency"
                // select={true}
                options={REPORTING_FREQUENCY}
                helperText=""
                onChange={(e, newValue) => {
                  setFields("reportingFrequency", newValue);
                }}
                fullWidth
              />
            </Grid>

            <Grid item sm={4} xs={12}>
              <AutoComplete
                variant="filled"
                label="Assessment Template"
                color="secondary"
                value={{
                  ...templateList.find(
                    (a) =>
                      a._id ===
                      (typeof field.assessmentTemplate == "object"
                        ? field.assessmentTemplate._id
                        : field.assessmentTemplate)
                  ),
                }}
                name="assessmentTemplate"
                // select={true}
                options={templateList}
                helperText=""
                disabled={field.assessmentTemplate}
                isError={errors.assessmentTemplate}
                onChange={(e, newValue) => {
                  setFields("assessmentTemplate", newValue);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="Part Name (Optional)"
                variant="filled"
                color="secondary"
                name="partName"
                value={field.partName}
                // select={true}
                // options={templateList}
                fullWidth
                onChange={(e) => {
                  setFields("partName", e.target.value);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="Part Number (Optional)"
                variant="filled"
                color="secondary"
                name="partNumber"
                value={field.partNumber}
                // select={true}
                // options={templateList}
                fullWidth
                onChange={(e) => {
                  setFields("partNumber", e.target.value);
                }}
              />
            </Grid>
            {/* <Grid item sm={4} xs={12}>
              <TextField
                label="Problem Description"
                variant="filled"
                color="secondary"
                name="problemDescription"
                value={field.problemDescription}
                // select={true}
                // options={templateList}
                fullWidth
                onChange={(e) => {
                  setFields("problemDescription", e.target.value);
                }}
              />
            </Grid> */}
            <Grid item sm={4} xs={12}>
              <AutoComplete
                variant="filled"
                label="Type of work required"
                color="secondary"
                value={{
                  ...workType.map(a => ({ name: a.label, _id: a.value })).find(
                    (a) => a._id === (typeof field.typeOfWorkRequired === 'object' ? field.typeOfWorkRequired._id : field.typeOfWorkRequired))
                }
                }
                name="typeOfWorkRequired"
                // select={true}
                options={workType.map(a => ({ name: a.label, _id: a.value }))}
                helperText=""
                isError={errors.typeOfWorkRequired}
                onChange={(e, newValue) => {
                  setFields("typeOfWorkRequired", newValue);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <AutoComplete
                variant="filled"
                label="Commodity"
                color="secondary"
                value={
                  {
                    ...commodityList.map(a => ({ name: a.label, _id: a.value })).find(
                      (a) => a._id === (typeof field.commodity === 'object' ? field.commodity._id : field.commodity)
                    )
                  }
                }
                name="commodity"
                // select={true}
                options={commodityList.map(a => ({ name: a.label, _id: a.value }))}
                helperText=""
                isError={errors.commodity}
                onChange={(e, newValue) => {
                  setFields("commodity", newValue);
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                label="Project Requirement"
                variant="filled"
                color="secondary"
                name="projectRequirement"
                value={field.projectRequirement}

                fullWidth
                onChange={(e) => {
                  setFields("projectRequirement", e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid style={{ textAlign: "center" }}>
            <Button
              style={{ marginTop: "20px", marginBottom: "10px" }}
              width="150px"
              text="Submit"
              variant="contained"
              color="primary"
              onClick={() => {
                // console.log(field)
                onSubmit();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

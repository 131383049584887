import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { clearProjectData, getProject } from "../../../redux/actions/project";
import { useEffect } from "react";
import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Table, Tooltip, Button, IconButton } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import moment from "moment";
import ProjectDetailComponent from "../ProjectDetailComponent";
import { clearPlanData, listPlans } from "../../../redux/actions/projectPlan";
import { GET_PROJECT, LIST_WORK_PLAN } from "../../../redux/action_types";
import { EVERSIGNPROJECTCREATION, EVERSIGNPROJECTREVIEW } from "../AllProjects/CONSTANTS";
import { ArrowBack, Close as CloseIcon } from "@material-ui/icons";
import MuiIconButton from '@material-ui/core/IconButton'
import Iframe from "react-iframe";



export default function Index() {
  const history = useHistory();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const projectPlans = useSelector((state) => state.projectPlan);
  const [planList, setPlanList] = useState([]);

  const [data, setdata] = useState({ workLocation: [] });
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [openTemplate, setOpenTemplate] = useState(false);
  useEffect(() => {
    if (projectId) {
      dispatch(getProject(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (project.detail?.data?._id) {
      dispatch(clearProjectData(GET_PROJECT));
      // console.log(project.detail.data)
      setdata(project.detail.data);
      dispatch(listPlans({ project: projectId, limit: 0 }));
    }
  }, [project]);
  useEffect(() => {
    if (projectPlans.list?.data?.length) {
      dispatch(clearPlanData(LIST_WORK_PLAN))
      setPlanList(projectPlans.list?.data);
    }
  }, [projectPlans]);
  useEffect(() => {
    // console.log(data);
    let dList = [], createProjectAgreement = '', reviewProjectAgreement = '';
    if (data.eversignList && data.eversignList.length) {
      let agreement = data.eversignList.find(a => a.type == EVERSIGNPROJECTCREATION) || {};
      createProjectAgreement = { pdf: agreement.meta?.finalPdf, date: agreement.updatedAt };
      agreement = data.eversignList.find(a => a.type == EVERSIGNPROJECTREVIEW) || {};
      reviewProjectAgreement = { pdf: agreement.meta?.finalPdf, date: agreement.updatedAt };
    }
    if (data.status === "Awaiting Work plan" ||
      data.status === "Completed") {
      dList = [
        {
          id: 1,
          name: "Project Contract",
          date: moment(createProjectAgreement.date).format("YYYY-MM-DD"),
          component: createProjectAgreement ? <Iframe src={createProjectAgreement.pdf} className="agreement-pdf" styles={{ width: '110vw', height: '100%', margin: '0 auto' }}>
          </Iframe > : "Agreement not created yet."
          // component: <ConfirmationTemplate data={data} eSign={true} />,
        },
        {
          id: 2,
          name: "Risk Assessment",
          date: moment(reviewProjectAgreement.date).format("YYYY-MM-DD"),
          component: createProjectAgreement ? <Iframe src={reviewProjectAgreement.pdf} className="agreement-pdf" styles={{ width: '110vw', height: '100%', margin: '0 auto' }}>
          </Iframe > : "Agreement not created yet."
          // component: <ProcessAudit data={data} eSign={true} />,
        },
        // {
        //   id: 3,
        //   name: 'Work Plan',
        //   date: moment(data.updatedAt).format('YYYY-MM-DD'),
        //   component: <IllustrationTemplate data={data} eSign={true} />
        // }
      ];
      for (let i = 0; i < planList.length; i++) {
        let document = {};
        document = { pdf: planList[i]?.eversignList[0]?.meta?.finalPdf, date: planList[i]?.eversignList[0]?.updatedAt };

        dList.push({
          id: 3 + i,
          name: "Work Plan - " + planList[i].name,
          date: moment(planList[i].updatedAt).format("YYYY-MM-DD"),
          component: document ? <Iframe src={document.pdf} className="agreement-pdf" styles={{ width: '110vw', height: '100%', margin: '0 auto' }}>
          </Iframe > : "Agreement not created yet."
          // component: (
          //   <IllustrationTemplate
          //     data={data}
          //     workPlan={planList[i]}
          //     eSign={true}
          //   />
          // ),
        });
      }
    }
    if (
      data.status === "Awaiting Customer to E-Sign" ||
      data.status === "Awaiting Review E-Sign BRM" ||
      data.status === "Awaiting to Risk Review" ||
      data.status === 'Awaiting Risk Assessment'
    ) {
      dList = [
        {
          id: 1,
          name: "Project Contract",
          date: moment(createProjectAgreement.date).format("YYYY-MM-DD"),
          component: createProjectAgreement ? <Iframe src={createProjectAgreement.pdf} className="agreement-pdf" styles={{ width: '110vw', height: '100%', margin: '0 auto' }}>
          </Iframe > : "Agreement not created yet."
          // component: <ConfirmationTemplate data={data} eSign={true} />,
        },
      ];
    }
    if (data.revProject) {
      dList.push({
        id: dList[dList.length - 1].id + 1,
        name: "Revesion Project",
        date: moment(createProjectAgreement.date).format("YYYY-MM-DD"),
        component: createProjectAgreement ? <Iframe src={createProjectAgreement.pdf} className="agreement-pdf" styles={{ width: '110vw', height: '100%', margin: '0 auto' }}>
        </Iframe > : "Agreement not created yet."
        // component: <ConfirmationTemplate data={data} eSign={true} />,
      })
    }
    let documnts = dList.map((a) => {
      a.documents = (
        <Tooltip
          title={`View Document`}
          placement="bottom"
          Component={() => (
            <Button
              onClick={(e) => {
                setSelectedDocument(a);
                setOpenTemplate(true);
                // dispatch(updateProject({ status: 'Awaiting Customer to E-Sign' }, project._id))
              }}
              text="View Document"
              variant="contained"
              color="primary"
              style={{ marginBottom: ".5rem" }}
            />
          )}
        />
      );
      return a;
    });

    setDocuments(documnts);
    // console.log(documnts);
  }, [data, planList]);

  return (
    <div style={{ background: "#fff" }}>
      <Grid style={{ margin: "0rem 3.5rem" }}>
        <h2>
          <IconButton
            color="primary"
            onClick={() => {
              history.push("/projects");
            }}
            icon=<ArrowBack />
          ></IconButton>
          Project Details
        </h2>
      </Grid>
      <ProjectDetailComponent data={data}></ProjectDetailComponent>
      <Grid style={{ height: "5rem", margin: "0rem 3rem" }}>
        <Table
          actions={false}
          header={TABLE_HEADER}
          data={documents}
          editPrefix="project/edit/"
        />
      </Grid>
      <div>
        <Dialog
          open={openTemplate}
          onClose={() => setOpenTemplate(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ minWidth: 950 }}
          className="plan-esign"
          fullScreen
        >
          <DialogTitle id="alert-dialog-title" onClose={() => setOpenTemplate(false)}>
            {selectedDocument?.name}
          </DialogTitle>
          <DialogContent style={{}}>{selectedDocument.component}</DialogContent>
          <DialogActions style={{ justifyContent: 'left' }}>
            <Grid xs={6} container style={{ justifyContent: 'center' }}>
              <Button
                onClick={(e) => {
                  setOpenTemplate(false);
                }}
                text="Ok"
                variant="contained"
                color="primary"
              />
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </div >
  );
}

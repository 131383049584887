import { 
  LIST_MODULE,
  GET_MODULE,
  ADD_MODULE,
  UPDATE_MODULE,
  DELETE_MODULE  
} from '../action_types';

const initialState = {
  list: {
      pagination: {},
      totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false
  }
}

const vehicleModule = (state=initialState, action)  => {
  const { type, payload } = action;

  switch(type) {
    case LIST_MODULE: 
      return {
        ...state,
        list: {
          ...state.list,
          ...payload
        }
      };
    case GET_MODULE:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload
        }
      };
    case ADD_MODULE:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          isLoading: false,
          hasError: payload.hasError,
        }
      }
    case UPDATE_MODULE:
      let updatedItem = payload.data;
      let tempList1 = [...state.list.data]
      tempList1.forEach((item, i) => {
        if(item._id === updatedItem._id) {
          tempList1[i] = updatedItem
        }
      })
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: tempList1
        }
      }
    case DELETE_MODULE:
      let deletedItem = payload.data;
      let tempList2 = [...state.list.data.filter(item => (item._id !== deletedItem._id))]
      return {
        ...state,
        list: {
          ...state.list,
          data: tempList2,
          isLoading:false,
          hasError: payload.hasError
        }
      }
    default: return state;
  }
}

export default vehicleModule;
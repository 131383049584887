export const SAMPLE_ACTION = 'SAMPLE_ACTION';

// AUTH
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const FORGOT = 'FORGOT';
export const VERIFY = 'VERIFY';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

// ACCOUNT
export const LIST_ACCOUNT = 'LIST_ACCOUNT';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

// BAY
export const LIST_BAY = 'LIST_BAY';
export const GET_BAY = 'GET_BAY';
export const ADD_BAY = 'ADD_BAY';
export const UPDATE_BAY = 'UPDATE_BAY';
export const DELETE_BAY = 'DELETE_BAY';

// BAY_TYPE
export const LIST_BAY_TYPE = 'LIST_BAY_TYPE';
export const GET_BAY_TYPE = 'GET_BAY_TYPE';
export const ADD_BAY_TYPE = 'ADD_BAY_TYPE';
export const UPDATE_BAY_TYPE = 'UPDATE_BAY_TYPE';
export const DELETE_BAY_TYPE = 'DELETE_BAY_TYPE';

// BOOK_BAY
export const LIST_BOOK_BAY = 'LIST_BOOK_BAY';
export const GET_BOOK_BAY = 'GET_BOOK_BAY';
export const ADD_BOOK_BAY = 'ADD_BOOK_BAY';
export const UPDATE_BOOK_BAY = 'UPDATE_BOOK_BAY';
export const DELETE_BOOK_BAY = 'DELETE_BOOK_BAY';

// COST_CENTRE
export const LIST_COST_CENTRE = 'LIST_COST_CENTRE';
export const GET_COST_CENTRE = 'GET_COST_CENTRE';
export const ADD_COST_CENTRE = 'ADD_COST_CENTRE';
export const UPDATE_COST_CENTRE = 'UPDATE_COST_CENTRE';
export const DELETE_COST_CENTRE = 'DELETE_COST_CENTRE';

// DASHBOARD
export const GET_STATIC_REPORT = "GET_STATIC_REPORT";
export const GET_RANGE_REPORT = "GET_RANGE_REPORT";
export const GET_CAR_PERFORMANCE_DATA = "GET_CAR_PERFORMANCE_DATA";
export const GET_ENG_PERFORMANCE_DATA = "GET_ENG_PERFORMANCE_DATA";
export const GET_BAY_PERFORMANCE_DATA = "GET_BAY_PERFORMANCE_DATA";
export const GET_MODULES_PERFORMANCE_DATA = "GET_MODULES_PERFORMANCE_DATA";
export const GET_DAY_WISE_REPORT = "GET_DAY_WISE_REPORT";
export const RESET_DASHBOARD = "RESET_DASHBOARD";

// VERSION UPDATE REPORT
export const GET_MODELS_PERFORMANCE = "GET_MODELS_PERFORMANCE";
export const RESET_MODELS_REPORT = "RESET_MODELS_REPORT";

// CAR HISTORY
export const LIST_CARS = "LIST_CARS";
export const GET_CAR_HISTORY = "GET_CAR_HISTORY";

// DATE_RANGE
export const SET_DATE_RANGE = "SET_DATE_RANGE";

// JOBCARD
export const LIST_JOBCARD = 'LIST_JOBCARD';
export const GET_JOBCARD = 'GET_JOBCARD';
export const ADD_JOBCARD = 'ADD_JOBCARD';
export const UPDATE_JOBCARD = 'UPDATE_JOBCARD';
export const DELETE_JOBCARD = 'DELETE_JOBCARD';

// JOB_PARAMETER
export const LIST_JOB_PARAMETER = 'LIST_JOB_PARAMETER';
export const GET_JOB_PARAMETER = 'GET_JOB_PARAMETER';
export const ADD_JOB_PARAMETER = 'ADD_JOB_PARAMETER';
export const UPDATE_JOB_PARAMETER = 'UPDATE_JOB_PARAMETER';
export const DELETE_JOB_PARAMETER = 'DELETE_JOB_PARAMETER';

// JOB_TYPE
export const LIST_JOB_TYPE = 'LIST_JOB_TYPE';
export const GET_JOB_TYPE = 'GET_JOB_TYPE';
export const ADD_JOB_TYPE = 'ADD_JOB_TYPE';
export const UPDATE_JOB_TYPE = 'UPDATE_JOB_TYPE';
export const DELETE_JOB_TYPE = 'DELETE_JOB_TYPE';

// LOCATION
export const LIST_LOCATION = 'LIST_LOCATION';
export const GET_LOCATION = 'GET_LOCATION';
export const ADD_LOCATION = 'ADD_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';
// ACCIDENT
export const LIST_ACCIDENT = 'LIST_ACCIDENT';
export const REPORT_ACCIDENT = 'REPORT_ACCIDENT';
export const GET_ACCIDENT = 'GET_ACCIDENT';
export const ADD_ACCIDENT = 'ADD_ACCIDENT';
export const UPDATE_ACCIDENT = 'UPDATE_ACCIDENT';
export const DELETE_ACCIDENT = 'DELETE_ACCIDENT';
// TICKET
export const LIST_TICKET = 'LIST_TICKET';
export const REPORT_TICKET = 'REPORT_TICKET';
export const GET_TICKET = 'GET_TICKET';
export const ADD_TICKET = 'ADD_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';
// NOTE
export const LIST_NOTE = 'LIST_NOTE';
export const REPORT_NOTE = 'REPORT_NOTE';
export const GET_NOTE = 'GET_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';

// PLATFORM
export const LIST_PLATFORM = 'LIST_PLATFORM';
export const GET_PLATFORM = 'GET_PLATFORM';
export const ADD_PLATFORM = 'ADD_PLATFORM';
export const UPDATE_PLATFORM = 'UPDATE_PLATFORM';
export const DELETE_PLATFORM = 'DELETE_PLATFORM';

// MAKE
export const LIST_MAKE = 'LIST_MAKE';
export const GET_MAKE = 'GET_MAKE';
export const ADD_MAKE = 'ADD_MAKE';
export const UPDATE_MAKE = 'UPDATE_MAKE';
export const DELETE_MAKE = 'DELETE_MAKE';

// MODEL
export const LIST_MODEL = 'LIST_MODEL';
export const GET_MODEL = 'GET_MODEL';
export const ADD_MODEL = 'ADD_MODEL';
export const UPDATE_MODEL = 'UPDATE_MODEL';
export const DELETE_MODEL = 'DELETE_MODEL';

// MODULE
export const LIST_MODULE = 'LIST_MODULE';
export const GET_MODULE = 'GET_MODULE';
export const ADD_MODULE = 'ADD_MODULE';
export const UPDATE_MODULE = 'UPDATE_MODULE';
export const DELETE_MODULE = 'DELETE_MODULE';

// VARIANT
export const LIST_VARIANT = 'LIST_VARIANT';
export const GET_VARIANT = 'GET_VARIANT';
export const ADD_VARIANT = 'ADD_VARIANT';
export const UPDATE_VARIANT = 'UPDATE_VARIANT';
export const DELETE_VARIANT = 'DELETE_VARIANT';

// VEHICLE
export const LIST_VEHICLE = 'LIST_VEHICLE';
export const GET_VEHICLE = 'GET_VEHICLE';
export const ADD_VEHICLE = 'ADD_VEHICLE';
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';

// PART_COMPONENT
export const LIST_PART_COMPONENT = 'LIST_PART_COMPONENT';
export const GET_PART_COMPONENT = 'GET_PART_COMPONENT';
export const ADD_PART_COMPONENT = 'ADD_PART_COMPONENT';
export const UPDATE_PART_COMPONENT = 'UPDATE_PART_COMPONENT';
export const DELETE_PART_COMPONENT = 'DELETE_PART_COMPONENT';

// PART_TYPE
export const LIST_PART_TYPE = 'LIST_PART_TYPE';
export const GET_PART_TYPE = 'GET_PART_TYPE';
export const ADD_PART_TYPE = 'ADD_PART_TYPE';
export const UPDATE_PART_TYPE = 'UPDATE_PART_TYPE';
export const DELETE_PART_TYPE = 'DELETE_PART_TYPE';

// NEW_PART
export const LIST_NEW_PART = 'LIST_NEW_PART';
export const GET_NEW_PART = 'GET_NEW_PART';
export const ADD_NEW_PART = 'ADD_NEW_PART';
export const UPDATE_NEW_PART = 'UPDATE_NEW_PART';
export const DELETE_NEW_PART = 'DELETE_NEW_PART';

// ROLE
export const LIST_ROLE = 'LIST_ROLE';
export const GET_ROLE = 'GET_ROLE';
export const ADD_ROLE = 'ADD_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
// COMAPNY_ROLE
export const LIST_COMAPNY_ROLE = 'LIST_COMAPNY_ROLE';
export const GET_COMAPNY_ROLE = 'GET_COMAPNY_ROLE';
export const ADD_COMAPNY_ROLE = 'ADD_COMAPNY_ROLE';
export const UPDATE_COMAPNY_ROLE = 'UPDATE_COMAPNY_ROLE';
export const DELETE_COMAPNY_ROLE = 'DELETE_COMAPNY_ROLE';

// TASK_STATUS
export const LIST_TASK_STATUS = 'LIST_TASK_STATUS';
export const GET_TASK_STATUS = 'GET_TASK_STATUS';
export const ADD_TASK_STATUS = 'ADD_TASK_STATUS';
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS';
export const DELETE_TASK_STATUS = 'DELETE_TASK_STATUS';

// TEAM
export const LIST_TEAM = 'LIST_TEAM';
export const GET_TEAM = 'GET_TEAM';
export const ADD_TEAM = 'ADD_TEAM';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';

// TEAM_TYPE
export const LIST_TEAM_TYPE = 'LIST_TEAM_TYPE';
export const GET_TEAM_TYPE = 'GET_TEAM_TYPE';
export const ADD_TEAM_TYPE = 'ADD_TEAM_TYPE';
export const UPDATE_TEAM_TYPE = 'UPDATE_TEAM_TYPE';
export const DELETE_TEAM_TYPE = 'DELETE_TEAM_TYPE';

// USER
export const LIST_USER = 'LIST_USER';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

// WIP_STATUS
export const LIST_WIP_STATUS = 'LIST_WIP_STATUS';
export const GET_WIP_STATUS = 'GET_WIP_STATUS';
export const ADD_WIP_STATUS = 'ADD_WIP_STATUS';
export const UPDATE_WIP_STATUS = 'UPDATE_WIP_STATUS';
export const DELETE_WIP_STATUS = 'DELETE_WIP_STATUS';

// WIP_VEHICLE
export const LIST_WIP_VEHICLE = 'LIST_WIP_VEHICLE';
export const GET_WIP_VEHICLE = 'GET_WIP_VEHICLE';
export const ADD_WIP_VEHICLE = 'ADD_WIP_VEHICLE';
export const UPDATE_WIP_VEHICLE = 'UPDATE_WIP_VEHICLE';
export const DELETE_WIP_VEHICLE = 'DELETE_WIP_VEHICLE';

// WIP_VEHICLE_TASK
export const LIST_WIP_VEHICLE_TASK = 'LIST_WIP_VEHICLE_TASK';
export const GET_WIP_VEHICLE_TASK = 'GET_WIP_VEHICLE_TASK';
export const ADD_WIP_VEHICLE_TASK = 'ADD_WIP_VEHICLE_TASK';
export const UPDATE_WIP_VEHICLE_TASK = 'UPDATE_WIP_VEHICLE_TASK';
export const DELETE_WIP_VEHICLE_TASK = 'DELETE_WIP_VEHICLE_TASK';

// WORKSHOP
export const LIST_WORKSHOP = 'LIST_WORKSHOP';
export const GET_WORKSHOP = 'GET_WORKSHOP';
export const ADD_WORKSHOP = 'ADD_WORKSHOP';
export const UPDATE_WORKSHOP = 'UPDATE_WORKSHOP';
export const DELETE_WORKSHOP = 'DELETE_WORKSHOP';

// WORKSHOP_TYPE
export const LIST_WORKSHOP_TYPE = 'LIST_WORKSHOP_TYPE';
export const GET_WORKSHOP_TYPE = 'GET_WORKSHOP_TYPE';
export const ADD_WORKSHOP_TYPE = 'ADD_WORKSHOP_TYPE';
export const UPDATE_WORKSHOP_TYPE = 'UPDATE_WORKSHOP_TYPE';
export const DELETE_WORKSHOP_TYPE = 'DELETE_WORKSHOP_TYPE';

// THEME
export const SET_THEME = 'SET_THEME';

// iTechQ start
// GRID
export const LIST_GRID = 'LIST_GRID';
export const GET_GRID = 'GET_GRID';
export const ADD_GRID = 'ADD_GRID';
export const UPDATE_GRID = 'UPDATE_GRID';
export const DELETE_GRID = 'DELETE_GRID';
// PART
export const LIST_PART = 'LIST_PART';
export const GET_PART = 'GET_PART';
export const ADD_PART = 'ADD_PART';
export const UPDATE_PART = 'UPDATE_PART';
export const DELETE_PART = 'DELETE_PART';
// export const UPLOAD_PART = 'UPLOAD_PART';

// COMPANY
export const LIST_COMPANY = 'LIST_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const ADD_COMPANY = 'ADD_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';

//ASSESSMENT
export const LIST_ASSESSMENT = 'LIST_ASSESSMENT';
export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const ADD_ASSESSMENT = 'ADD_ASSESSMENT';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const DELETE_ASSESSMENT = 'DELETE_ASSESSMENT';
// FILE
export const GET_FILE = 'GET_FILE';
export const ADD_FILE = 'ADD_FILE';
export const ADD_FILES = 'ADD_FILES';
export const DELETE_FILE = 'DELETE_FILE';
// FILE
export const SET_LOADER = 'SET_LOADER';
export const SET_LOCAL_COMPANY = 'SET_LOCAL_COMPANY';
// PROJECT
export const LIST_PROJECT = 'LIST_PROJECT';
export const LIST_PROJECT_REPORT = 'LIST_PROJECT_REPORT';
export const PROJECT_REPORT_HISTORY = 'PROJECT_REPORT_HISTORY';
export const LIST_PROJECT_ACTIVITY = 'LIST_PROJECT_ACTIVITY';
export const GET_PROJECT = 'GET_PROJECT';
export const CLONE_PROJECT = 'CLONE_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const RECOVER_PROJECT = 'RECOVER_PROJECT';
export const SIGN_PROJECT = 'SIGN_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

// TEMPLATE 
export const LIST_TEMPLATE = 'LIST_TEMPLATE';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

//PROJECT ASSESMENTS
export const LIST_PROJECT_ASSESMENTS = 'LIST_PROJECT_ASSESMENTS';
export const GET_PROJECT_ASSESMENTS = 'GET_PROJECT_ASSESMENTS';
export const ADD_PROJECT_ASSESMENTS = 'ADD_PROJECT_ASSESMENTS';
export const UPDATE_PROJECT_ASSESMENTS = 'UPDATE_PROJECT_ASSESMENTS';
export const DELETE_PROJECT_ASSESMENTS = 'DELETE_PROJECT_ASSESMENTS';

//PROJECT WORK PLANS
export const LIST_WORK_PLAN = 'LIST_WORK_PLAN';
export const LIST_WORK_PLAN_REPORT = 'LIST_WORK_PLAN_REPORT';
export const LIST_WORK_PLAN_VEHICLE_REPORT = 'LIST_WORK_PLAN_VEHICLE_REPORT';
export const GET_WORK_PLAN = 'GET_WORK_PLAN';
export const ADD_WORK_PLAN = 'ADD_WORK_PLAN';
export const ADD_WORK_PLAN_INSPECTOR = 'ADD_WORK_PLAN_INSPECTOR';
export const UPDATE_WORK_PLAN = 'UPDATE_WORK_PLAN';
export const SIGN_WORK_PLAN = 'SIGN_WORK_PLAN';
export const DELETE_WORK_PLAN = 'DELETE_WORK_PLAN';
//OBJECT CHECKPOINT
export const LIST_OBJECT_CHECKPOINT = 'LIST_OBJECT_CHECKPOINT';
export const GET_OBJECT_CHECKPOINT = 'GET_OBJECT_CHECKPOINT';
export const ADD_OBJECT_CHECKPOINT = 'ADD_OBJECT_CHECKPOINT';
export const ADD_OBJECT_CHECKPOINT_INSPECTOR = 'ADD_OBJECT_CHECKPOINT_INSPECTOR';
export const UPDATE_OBJECT_CHECKPOINT = 'UPDATE_OBJECT_CHECKPOINT';
export const DELETE_OBJECT_CHECKPOINT = 'DELETE_OBJECT_CHECKPOINT';
//PLAN ACTIVITY
export const LIST_PLAN_ACTIVITY = 'LIST_PLAN_ACTIVITY';
export const GET_PLAN_ACTIVITY = 'GET_PLAN_ACTIVITY';
export const ADD_PLAN_ACTIVITY = 'ADD_PLAN_ACTIVITY';
export const UPDATE_PLAN_ACTIVITY = 'UPDATE_PLAN_ACTIVITY';
export const DELETE_PLAN_ACTIVITY = 'DELETE_PLAN_ACTIVITY';
//OBJECT CHECKPOINT LOG
export const LIST_OBJECT_CHECKPOINT_LOG = 'LIST_OBJECT_CHECKPOINT_LOG';
export const GET_OBJECT_CHECKPOINT_LOG = 'GET_OBJECT_CHECKPOINT_LOG';
export const ADD_OBJECT_CHECKPOINT_LOG = 'ADD_OBJECT_CHECKPOINT_LOG';
export const UPDATE_OBJECT_CHECKPOINT_LOG = 'UPDATE_OBJECT_CHECKPOINT_LOG';
export const DELETE_OBJECT_CHECKPOINT_LOG = 'DELETE_OBJECT_CHECKPOINT_LOG';
//PLAN ACTIVITY LOG
export const LIST_PLAN_ACTIVITY_LOG = 'LIST_PLAN_ACTIVITY_LOG';
export const GET_PLAN_ACTIVITY_LOG = 'GET_PLAN_ACTIVITY_LOG';
export const ADD_PLAN_ACTIVITY_LOG = 'ADD_PLAN_ACTIVITY_LOG';
export const UPDATE_PLAN_ACTIVITY_LOG = 'UPDATE_PLAN_ACTIVITY_LOG';
export const DELETE_PLAN_ACTIVITY_LOG = 'DELETE_PLAN_ACTIVITY_LOG';
//TECHNICIAN WORK PLANS
export const LIST_TECHNICIAN_WORK_PLAN = 'LIST_TECHNICIAN_WORK_PLAN';
export const LIST_TECHNICIAN_WORK_PLAN_REPORT = 'LIST_TECHNICIAN_WORK_PLAN_REPORT';
export const GET_TECHNICIAN_WORK_PLAN = 'GET_TECHNICIAN_WORK_PLAN';
export const ADD_TECHNICIAN_WORK_PLAN = 'ADD_TECHNICIAN_WORK_PLAN';
export const UPDATE_TECHNICIAN_WORK_PLAN = 'UPDATE_TECHNICIAN_WORK_PLAN';
export const DELETE_TECHNICIAN_WORK_PLAN = 'DELETE_TECHNICIAN_WORK_PLAN';
//TECHNICIAN WORK PLANS HISTORY
export const LIST_TECHNICIAN_WORK_PLAN_HISTORY = 'LIST_TECHNICIAN_WORK_PLAN_HISTORY';
export const GET_TECHNICIAN_WORK_PLAN_HISTORY = 'GET_TECHNICIAN_WORK_PLAN_HISTORY';
export const ADD_TECHNICIAN_WORK_PLAN_HISTORY = 'ADD_TECHNICIAN_WORK_PLAN_HISTORY';
export const UPDATE_TECHNICIAN_WORK_PLAN_HISTORY = 'UPDATE_TECHNICIAN_WORK_PLAN_HISTORY';
export const DELETE_TECHNICIAN_WORK_PLAN_HISTORY = 'DELETE_TECHNICIAN_WORK_PLAN_HISTORY';
//PROJECT WORK PLANS ASSESMENTS
export const LIST_WORK_PLAN_ASSESMENTS = 'LIST_WORK_PLAN_ASSESMENTS';
export const GET_WORK_PLAN_ASSESMENTS = 'GET_WORK_PLAN_ASSESMENTS';
export const ADD_WORK_PLAN_ASSESMENTS = 'ADD_WORK_PLAN_ASSESMENTS';
export const UPDATE_WORK_PLAN_ASSESMENTS = 'UPDATE_WORK_PLAN_ASSESMENTS';
export const DELETE_WORK_PLAN_ASSESMENTS = 'DELETE_WORK_PLAN_ASSESMENTS';

//PROJECT WORK PLANS VEHICLES
export const LIST_WORK_PLAN_VEHICLES = 'LIST_WORK_PLAN_VEHICLES';
export const GET_WORK_PLAN_VEHICLES = 'GET_WORK_PLAN_VEHICLES';
export const ADD_WORK_PLAN_VEHICLES = 'ADD_WORK_PLAN_VEHICLES';
export const UPDATE_WORK_PLAN_VEHICLES = 'UPDATE_WORK_PLAN_VEHICLES';
export const DELETE_WORK_PLAN_VEHICLES = 'DELETE_WORK_PLAN_VEHICLES';
import {
  LIST_PART,
  GET_PART,
  ADD_PART,
  UPDATE_PART,
  DELETE_PART,
  // UPLOAD_PART
} from "../action_types";
import axios from "axios";
// import { QS } from '../../utils/urlHelper';

export const listParts = (params) => async (dispatch) => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: "GET",
      url: `/part`,
      params,
    });
    dispatch({
      type: LIST_PART,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: LIST_PART,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      },
    });
  }
};

export const getPart = (id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "GET",
      url: `/part/${id}`,
    });
    dispatch({
      type: GET_PART,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_PART,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      },
    });
  }
};

export const addPart = (data) => async (dispatch) => {
  try {
    let res = await axios({
      method: "POST",
      url: `/part`,
      data,
    });
    dispatch({
      type: ADD_PART,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: ADD_PART,
      payload: {
        data: {},
        hasError: true,
      },
    });
  }
};

export const updatePart = (data, id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "PUT",
      url: `/part/${id}`,
      data,
    });
    dispatch({
      type: UPDATE_PART,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: UPDATE_PART,
      payload: {
        data: {},
        hasError: true,
      },
    });
  }
};

export const deletePart = (id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "DELETE",
      url: `/part/${id}`,
    });
    // console.log(res.data.data);
    dispatch({
      type: DELETE_PART,
      payload: {
        data: res.data.data || { _id: id },
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: DELETE_PART,
      payload: {
        data: {},
        hasError: true,
      },
    });
  }
};
export const clearPartData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_PART,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
};